import axios from "./axios";
const handleCatch = (error) => {
  if (error.response) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        const myObject = error.response.data.errors;
        const firstKey = Object.keys(myObject)[0];
        const firstValue = myObject[firstKey];
        throw new Error(firstValue[0]);
      } else {
        throw new Error(error.response.data.message);
      }
    } else if (error.response.status === 401) {
      // localStorage.clear();
      // window.location.replace(window.location.origin+'/unauthorized')
      // window.location.reload();
    }
    else if (error.response.status === 422 || error.response.status === 400){
      throw new Error(error.response.data.message);

    }
       else {
      throw new Error("An error occurred on the server.");
    }
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
  throw error;
};

export const LoginUser = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/user/login`, userData, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const SignupUser = async (userData) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/user/signup`, userData, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export function LogoutUser() {
  const Admintoken = localStorage.getItem("adminToken");
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${Admintoken}`,
      };

      const response = await axios.post(`/logout`, null, {
        headers,
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
      console.error("Error:", error);
    }
  });
}

export const LoginAdmin = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/login`, userData, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const ForgetPassword = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/forgot-password`, Data, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const CodeVerify = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/reset/verify`, Data, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const ResetPassword = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`reset-password`, Data, {
      headers: myHeaders,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AdminListUser = async ({ per_page, page }) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/users/list?page=${page}`,
      { per_page },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AdminBlockUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/block-admin-users`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/users/delete`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AdminListRoles = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/role-dropdown`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const EditAdminUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/edit-users`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/users/create`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const UpdateAdminUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/users/update`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAgentPassword = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/users/reset/password`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminListRole = async (currentPage, itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/list-role?page=${currentPage + 1}`,
      { per_page: itemsPerPage },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const SearchAgentsService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/users?page=${data.page}`,
      {
        per_page: data.per_page,
        search: data.searchText,
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const PermissionDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/permission-dropdown`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/add-role`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/create/category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/update/category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const GetCategoriesList = async (page, per_page) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/list/category?page=${page}`,
      { per_page },
      { headers }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const SearchCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/category?page=${data.page}`,
      {
        per_page: data.per_page,
        search: data.searchText,
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const DeleteCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/delete/category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const GetSubCategoriesByCategoryIdList = async (category_id) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/sub_category_list`,
      { category_id },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const GetSubCategoriesList = async (page, per_page) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/list/subcategory?page=${page}`,
      { per_page },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const SearchSubcategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/subcategory?page=${data.page}`,
      {
        per_page: data.per_page,
        search: data.searchText,
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddSubCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/create/subcategory`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/update-role`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateSubCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      // Accept: "application/json",
      // "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/update/subcategory`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteSubCategoryService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/delete/subcategory`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/delete-role`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminViewPermission = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/view-permission`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminPermission = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/add-permission`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminListCategories = async (currentPage, itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/view-category?page=${currentPage + 1}`,
      { per_page: itemsPerPage },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/add-category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/edit-category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/delete-category`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const CategoriesDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/category-dropdown`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminListTags = async (currentPage, itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/view-tag?page=${currentPage + 1}`,
      { per_page: itemsPerPage },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/create-tag`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/edit-tag`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/delete-tag`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const TagTypeDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/dropdown-tagtype`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddTagType = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/admin/add-tagtype`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminListSeries = async (currentPage, itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/view-series?page=${currentPage + 1}`,
      { per_page: itemsPerPage },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const CreateAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/create-series`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/edit-series`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/delete-series`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AdminTagDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/tagdropdown`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const MostUsedTags = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/tag-mostused`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const SeriesDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/dropdown-series`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AdminListClasses = async (currentPage, itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/admin/view-classes?page=${currentPage + 1}`,
      { per_page: itemsPerPage },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const AddAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/add-classes`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const DeleteAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/delete-classes`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`admin/edit-classes`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const FetchCustomersList = async (page, per_page) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/customer/list?page=${page}`,
      { per_page },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const SearchCustomersService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/customer?page=${data.page}`,
      {
        per_page: data.per_page,
        search: data.searchText,
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error("Error searching customers:", error);
    throw error;
  }
};

export const ChangeCustomerStatusService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/customer/status`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// ADD POSTS APIS

export const GetAddPostsList = async (page, per_page) => {
  // console.log("🚀 ~ GetAddPostsList ~ per_page:", per_page);
  // console.log("🚀 ~ GetAddPostsList ~ page:", page);
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/ads/list?page=${page}`,
      { per_page },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const SearchAdsService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/ads?page=${data.page}`,
      {
        per_page: data.per_page,
        search: data.searchText,
        agent_id:data.agentId || null
      },
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const GetSingleAddPost = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/single/ad/list`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const ApproveRejctAddPostService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/ad/status`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const DeleteAddPostService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/ad/delete`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const ChangeAddTypeService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`ad/change/type`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
export const UpdateAddService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
     
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/ad/update`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// BANNER API's

export const GetBannerService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/banner/list`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AddBannerService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/create/banner`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const UpdateBannerService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/update/banner`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const DeleteBannerImageService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/delete/banner/image`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// permissions

export const FetchPermissionsService = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/permissions`, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
// dashboard stats

export const FetchDashboardStatsService = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    // console.log("🚀 ~ FetchDashboardStatsService ~ Admintoken:", Admintoken);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/dashboard`, null, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// orders APIs

export const SearchOrderService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/search/order?page=${data?.page}`,
      {
        per_page: data.per_page,
        search: data?.searchText,
        agent_id: data.agentId || null,
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const FetchSingleOrderService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/order/single/list`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const ApproveRejctOrderService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/order/status`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// agent list

export const AgentListService = async () => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    // console.log("🚀 ~ AgentListService ~ Admintoken:", Admintoken);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(
      `/agent/list`,
      {},
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

export const AssignAdToAgentService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/assign/ad/agent`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};

// delete customer API

export const RemoveCustomerService = async (data) => {
  try {
    const Admintoken = localStorage.getItem("adminToken");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Admintoken}`,
    };

    const response = await axios.post(`/delete/customer`, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleCatch(error);
  }
};
