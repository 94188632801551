import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Text,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { AddAdminUser, AdminListUser, SearchAgentsService, UpdateAdminUser } from "../../service/service";
import Swal from "sweetalert2";
import { EditIcon } from "@chakra-ui/icons";

function UpdateUserModal({ adminData, setUserData, currentPage, itemsPerPage,searchText }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    status: "0"
  });
  const [formErrors, setFormErrors] = useState({});

  const permissionsTypes = ["create", "read", "update", "delete"];
  const initialPermissions = {
    ads: { read: false, update: false },
    banner: { create: false, read: false, update: false, delete: false },
    categories: { create: false, read: false, update: false, delete: false },
    customer: { create: false, read: false, update: false, delete: false },
    dashboard: { read: false },
    order: { read: false, update: false },
    slider: { create: false, read: false, update: false, delete: false },
    users_accounts: { create: false, read: false, update: false, delete: false },
  };
  const [permissions, setPermissions] = useState(initialPermissions);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && adminData) {
      const { name, email, permissions: permissionsJson, status } = adminData;
      setFormData({ name, email, password: "", status: status.toString() }); // Assuming password shouldn't be pre-filled
      try {
        const permissionsParsed = JSON.parse(permissionsJson);
        setPermissions(permissionsParsed);
      } catch (error) {
        console.error("Failed to parse permissions:", error);
        setPermissions(initialPermissions);
      }
    }
  }, [isOpen, adminData]);

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate Name
    if (!formData.name) {
      isValid = false;
      errors.name = "Name is required.";
    }

    // Validate Email
    if (!formData.email) {
      isValid = false;
      errors.email = "Email is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      isValid = false;
      errors.email = "Email is invalid.";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePermissionChange = (module, perm) => {
    setPermissions((prev) => ({
      ...prev,
      [module]: {
        ...prev[module],
        [perm]: !prev[module][perm],
      },
    }));
  };

  const toggleColumn = (perm) => {
    const allChecked = Object.keys(permissions).every(
      (module) => !permissions[module].hasOwnProperty(perm) || permissions[module][perm]
    );
    setPermissions((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((module) => {
        if (prev[module].hasOwnProperty(perm)) {
          updated[module][perm] = !allChecked;
        }
      });
      return updated;
    });
  };

  const allChecked = (perm) => {
    return Object.keys(permissions).every(
      (module) => !permissions[module].hasOwnProperty(perm) || permissions[module][perm]
    );
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const data = {
          id: adminData?.id,
          name: formData.name,
          email: formData.email,
          status: formData.status,
          permissions: permissions,
        };
        const result = await UpdateAdminUser(data);
        if (result.status) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            password: "",
            status: "0"
          });
          onClose();

          const userList = await SearchAgentsService({
            searchText,
            per_page: itemsPerPage,
            page: currentPage + 1,
          });
          if (userList.status) {
            setUserData(userList.data.data);
          } else {
            Swal.fire({
              title: 'Error!',
              text: userList.message,
              icon: 'error',
              timer: 3000,
              confirmButtonText: 'OK'
            });
          }

          Swal.fire({
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <ChakraProvider>
      <EditIcon cursor={'pointer'} color='teal' onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Update User</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" py={6}>
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={formErrors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formErrors.name && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.name}
                  </Text>
                )}
              </FormControl>

              <FormControl mt="4" isRequired isInvalid={formErrors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.email}
                  </Text>
                )}
              </FormControl>

              <FormControl mt="4" isRequired isInvalid={formErrors.status}>
                <FormLabel>Status</FormLabel>
                <RadioGroup
                  onChange={(value) =>
                    setFormData({ ...formData, status: value })
                  }
                  value={formData.status}
                >
                  <Stack direction="row">
                    <Radio value="1">Active</Radio>
                    <Radio value="0">Inactive</Radio>
                  </Stack>
                </RadioGroup>
                {formErrors.status && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.status}
                  </Text>
                )}
              </FormControl>
            </Box>
            <Box flex="2">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Module/Permissions</Th>
                    {permissionsTypes.map((type) => (
                      <Th key={type}>
                        <Checkbox
                          fontSize='sm'
                          isChecked={allChecked(type)}
                          onChange={() => toggleColumn(type)}
                        >
                          {type}
                        </Checkbox>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(permissions).map((module, index) => (
                    <Tr key={index}>
                      <Td>{module}</Td>
                      {permissionsTypes.map((perm) => (
                        <Td key={perm}>
                          {permissions[module].hasOwnProperty(perm) && (
                            <Checkbox
                              isChecked={permissions[module][perm]}
                              onChange={() =>
                                handlePermissionChange(module, perm)
                              }
                            />
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr="3" onClick={onClose}>
              Close
            </Button>
            <Button isLoading={loading} onClick={handleSave} colorScheme="teal">
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default UpdateUserModal;
