import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import {
  AgentListService,
  AssignAdToAgentService,
} from "../../service/service";

function AssignAdToAgentModal({ ad_id, fetchData,agentId }) {
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      AgentListService()
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
            setAgents(result.data);
            setSelectedAgent(agentId)
          } else {
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    setSelectedAgent(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, selectedAgent: "" }));
    }
  };

  const validate = () => {
    const errors = {};
    if (!selectedAgent) {
      errors.selectedAgent = "Agent is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      AssignAdToAgentService({ id: ad_id, assignAgent_id: selectedAgent })
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
            Swal.fire({
              icon: "success",
              title: "Agent Assigned Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            onClose();
            setSelectedAgent("");
            fetchData(); // Call the fetchData function to refresh the table
          } else {
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
      <div onClick={onOpen}>Assign Agent</div>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setSelectedAgent("");
          onClose();
        }}
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Assign Agent</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={errors.selectedAgent}>
                <FormLabel>Select Agent</FormLabel>
                <Select
                  placeholder="Select agent"
                  value={selectedAgent}
                  onChange={handleChange}
                >
                  {agents.map((agent) => (
                    <option key={agent.id} value={agent.id}>
                      {agent.name}
                    </option>
                  ))}
                </Select>
                {errors.selectedAgent && (
                  <FormErrorMessage>{errors.selectedAgent}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setSelectedAgent("");
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Assign
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AssignAdToAgentModal;
