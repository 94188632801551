import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import { ThreeCircles } from "react-loader-spinner";
import { SearchOrderService } from "../../service/service";
import Swal from "sweetalert2";
import OrdersTable from "../../adminComponents/orders/OrdersTable";
import { useLocation } from "react-router-dom";

function OrdersManagement() {
  const { state } = useLocation();
  // console.log("🚀 ~ OrdersManagement ~ state:", state);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [noData, setNoData] = useState("");
  const [searchText, setsearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  function handlePageChange(selectedPage) {
    setCurrentPage(selectedPage.selected);
    showOrderList(selectedPage.selected); // Page number for API is 1-based
  }
  const handleSearchChange = (event) => {
    setsearchText(event.target.value);
    showOrderList(currentPage, event.target.value); // Keep the current page when searching
  };

  useEffect(() => {
    showOrderList(currentPage, searchText); // Load the first page initially
  }, [currentPage]);

  const showOrderList = (page = currentPage, search = searchText) => {
    setIsLoading(true);
    SearchOrderService({
      page: page + 1,
      per_page: itemsPerPage,
      searchText: search,
      agentId: state?.agentId,
    })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setResponseData(result.data.data);
          setTotalPages(result.data.last_page);
          if (result.data.data.length === 0) {
            setNoData("No data to show");
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Orders Management"}
            subtitle={"Role Management"}
          />
          <OrdersTable
            responseData={responseData}
            setResponseData={setResponseData}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            noData={noData}
            setIsLoading={setIsLoading}
            setsearchText={setsearchText}
            handleSearchChange={handleSearchChange}
          />
        </main>
      </div>
    </>
  );
}

export default OrdersManagement;
