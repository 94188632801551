import React from 'react'
import logo from '../../assets/logo.png'
function LoginHeader() {
    return (
        <div className='pt-[52px]  flex w-full justify-center admin-logo-div mb-[80px]'>
            <img src={logo} alt="inside chassidus" className='w-[130px]' />
        </div>
    )
}

export default LoginHeader