import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Select,
  Image,
} from "@chakra-ui/react";
import {
  GetCategoriesList,
  SearchSubcategoryService,
  UpdateSubCategoryService,
} from "../../service/service"; // Assume this is the API call
import Swal from "sweetalert2";
import { MdOutlineEdit } from "react-icons/md";

function UpdateSubcategoryModal({
  searchText,
  setResponseData,
  setTotalPages,
  subCategoryData,
  currentPage,
  itemsPerPage,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [imagePreview, setImagePreview] = useState(
    subCategoryData?.image
      ? `${process.env.REACT_APP_IMG_API}/${subCategoryData.image}`
      : null
  );

  const [formData, setFormData] = useState({
    name: subCategoryData?.subcategory,
    description: subCategoryData?.description,
    file: null,
    category_id: subCategoryData?.category_id,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    GetCategoriesList().then((res) => {
      setCategoriesList(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: subCategoryData?.subcategory,
        description: subCategoryData?.description,
        file: null,
        category_id: subCategoryData?.category_id,
      });
      setImagePreview(
        subCategoryData?.image
          ? `${process.env.REACT_APP_IMG_API}/${subCategoryData.image}`
          : null
      );
    }
  }, [isOpen, subCategoryData]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
      setImagePreview(URL.createObjectURL(files[0]));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Subcategory name is required.";
    }
    if (!formData.description) {
      errors.description = "Description is required.";
    }
    if (!formData.category_id) {
      errors.category_id = "Category is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsLoading(true);
        const formDataPayload = new FormData();
        formDataPayload.append("id", subCategoryData?.id);
        formDataPayload.append("category_id", formData.category_id);
        formDataPayload.append("subcategory_name", formData.name);
        formDataPayload.append("description", formData.description);
        if (formData.file) {
          formDataPayload.append("image", formData.file);
        }

        UpdateSubCategoryService(formDataPayload).then((result) => {
          if (result.status) {
            SearchSubcategoryService({
              searchText,
              per_page: itemsPerPage,
              page: currentPage,
            })
              .then((result) => {
                setIsLoading(false);
                if (result.status) {
                  setResponseData(result.data.data);
                  setTotalPages(result.data.last_page);
                } else {
                  Swal.fire({
                    title: "Error!",
                    text: result.message,
                    icon: "error",
                    timer: 3000,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error.message);
                Swal.fire({
                  title: "Error!",
                  text: error.message,
                  icon: "error",
                  timer: 3000,
                  confirmButtonText: "OK",
                });
              });

            onClose();
            setIsLoading(false);
            Swal.fire({
              icon: "success",
              title: "Subcategory Updated Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setIsLoading(false);
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        });
      } catch (error) {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
      <MdOutlineEdit fontSize={20} color="teal" onClick={onOpen} />
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Update Subcategory</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={errors.name}>
                <FormLabel>Subcategory</FormLabel>
                <Input
                  placeholder="Enter subcategory name here"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={errors.description} mt={4}>
                <FormLabel>Description</FormLabel>
                <Input
                  placeholder="Enter description here"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
                {errors.description && (
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired isInvalid={errors.category_id} mt={4}>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select category"
                  name="category_id"
                  value={formData.category_id}
                  onChange={handleChange}
                >
                  {categoriesList.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category}
                    </option>
                  ))}
                </Select>
                {errors.category_id && (
                  <FormErrorMessage>{errors.category_id}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={errors.file} mt={4}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Box
                    textAlign={"center"}
                    borderWidth="1px"
                    borderRadius="md"
                    p="2"
                    cursor="pointer"
                    borderColor={errors.file ? "red.500" : "gray.200"}
                  >
                    {formData.file ? formData.file.name : "Choose Image"}
                  </Box>
                </label>
                {errors.file && (
                  <FormErrorMessage>{errors.file}</FormErrorMessage>
                )}
              </FormControl>
              {imagePreview && (
                <Image
                  src={imagePreview}
                  alt="Selected Image"
                  boxSize="200px"
                  mt="4"
                  objectFit="cover"
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr="3" onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default UpdateSubcategoryModal;
