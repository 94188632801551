import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Image,
} from "@chakra-ui/react";
import { AddCategory, SearchCategoryService } from "../../service/service";
import Swal from "sweetalert2";

function AddCategoryModal({
  setResponseData,
  setTotalPages,
  currentPage,
  itemsPerPage,
  searchText,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    file: null,
  });
  const [errors, setErrors] = useState({});
  console.log("🚀 ~ errors:", errors)


  const handleChange = (e) => {
    const { name, value, files,result} = e.target;
    if (name === "file" && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          file: "Only JPG, JPEG, and PNG files are allowed.",
        }));
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          file: "Image size must be less than 2MB.",
        }));
        return;
      }
      setErrors((prev) => ({
        ...prev,
        file: null,
      }));
      setFormData((prev) => ({ ...prev, [name]: file }));
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Category name is required.";
    }
    if (!formData.file) {
      errors.file = "Image is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsLoading(true);
        const formDataPayload = new FormData();
        formDataPayload.append("category_name", formData.name);
        formDataPayload.append("image", formData.file);

        AddCategory(formDataPayload).then((result) => {
          console.log("🚀 ~ AddCategory ~ result:", result)
          if (result.status) {
            SearchCategoryService({
              searchText,
              per_page: itemsPerPage,
              page: currentPage + 1,
            })
              .then((response) => {
                setIsLoading(false);
                if (response.status) {
                  setResponseData(response.data.data);
                  setTotalPages(response.data.last_page);
                }
                else {
                  Swal.fire({
                    title: "Error!",
                    text: response.message,
                    icon: "error",
                    timer: 3000,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error.message);
                Swal.fire({
                  title: "Error!",
                  text: error.message,
                  icon: "error",
                  timer: 3000,
                  confirmButtonText: "OK",
                });
              });

            onClose();
            setIsLoading(false);
            Swal.fire({
              icon: "success",
              title: "Category Added Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            setErrors((prev) => ({
              ...prev,
              name: result.message,
            }));
            setIsLoading(false);
            // Swal.fire({
            //   title: "Error!",
            //   text: result.message,
            //   icon: "error",
            //   timer: 3000,
            //   confirmButtonText: "OK",
            // });
          }
        });
      } catch (error) {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
      <Button onClick={onOpen} colorScheme="teal">
        Add Category
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setFormData({
            name: "",
            file: null,
          });
          setImagePreview(null);
          setErrors({});
          setIsLoading(false)
          onClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Add Category</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={errors.name}>
                <FormLabel>Category</FormLabel>
                <Input
                  placeholder="Enter category name here"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired mt="4" isInvalid={errors.file}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Box
                    textAlign={"center"}
                    borderWidth="1px"
                    borderRadius="md"
                    p="2"
                    cursor="pointer"
                    borderColor={errors.file ? "red.500" : "gray.200"}
                  >
                    {formData.file ? formData.file.name : "Choose Image"}
                  </Box>
                </label>
                {errors.file && (
                  <FormErrorMessage>{errors.file}</FormErrorMessage>
                )}
              </FormControl>
              {imagePreview && (
                <Image
                  src={imagePreview}
                  alt="Selected Image"
                  boxSize="200px"
                  mt="4"
                  objectFit="cover"
                />
              )}
               {/* {errors.general && (
                <Box color="red.500" mt="4">
                  {errors.general}
                </Box>
              )} */}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setFormData({
                  name: "",
                  file: null,
                });
                setImagePreview(null);
                setErrors({});
                setIsLoading(false)
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AddCategoryModal;
