import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  GetBannerService,
  UpdateBannerService,
  DeleteBannerImageService, // Make sure this is correctly imported
} from "../../service/service";
import Swal from "sweetalert2";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { ThreeCircles } from "react-loader-spinner";

function UpdateBannerModal({ banner, setResponseData, setTotalPages }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    title: "",
    images: [],
  });
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (banner) {
      setFormData({
        name: banner.banner_description,
        title: banner.banner_name,
        images: banner.images.map((img) => ({
          path: img.path,
          file: null,
          id: img.id,
        })),
      });
    }
  }, [banner]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => {
      const isValidFileType = ["image/jpeg", "image/png", "image/jpg"].includes(file.type);
      const isValidFileSize = file.size <= 2 * 1024 * 1024; // 2MB in bytes

      if (!isValidFileType || !isValidFileSize) {
        setErrors((prev) => ({
          ...prev,
          images: "Only images (png, jpeg, jpg) are allowed and each must be less than 2MB.",
        }));
        return false;
      }

      return true;
    });

    if (validFiles.length > 0) {
      const newImages = validFiles.map((file) => ({
        path: URL.createObjectURL(file),
        file,
      }));

      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...newImages],
      }));
      setErrors((prev) => ({
        ...prev,
        images: null,
      }));
    }
  };

  const handleRemoveImage = (index) => {
    const image = formData.images[index];
    if (image.file) {
      // If it's a newly uploaded image, just remove it from the state
      setFormData((prev) => ({
        ...prev,
        images: prev.images.filter((_, i) => i !== index),
      }));
    } else {
      // Call API to delete the image from the server
      setIsLoading(true);
      DeleteBannerImageService({ banner_path: image.path })
        .then((response) => {
          setIsLoading(false);
          if (response.status) {
            Swal.fire({
              icon: "success",
              title: "Image deleted successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            setFormData((prev) => ({
              ...prev,
              images: prev.images.filter((_, i) => i !== index),
            }));
          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    }
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Banner text is required.";
    }
    if (!formData.title) {
      errors.title = "Title is required.";
    }
    if (formData.images.length === 0) {
      errors.images = "At least one image is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const payload = new FormData();
      payload.append("banner_name", formData.title);
      payload.append("banner_id", banner.id);
      payload.append("banner_description", formData.name);
      formData.images.forEach((image, index) => {
        if (image.file) {
          payload.append(`banner_images[${index}]`, image.file);
        }
      });

      // console.log("FormData contents:");
      // for (let pair of payload.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      setIsLoading(true);
      UpdateBannerService(payload)
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
            GetBannerService()
              .then((result) => {
                if (result.status) {
                  setResponseData(result.data);
                  const totalPages = Math.ceil(result.data.total / 10);
                  setTotalPages(totalPages);
                } else {
                  Swal.fire({
                    title: "Error!",
                    text: result.message,
                    icon: "error",
                    timer: 3000,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error.message);
                Swal.fire({
                  title: "Error!",
                  text: error.message,
                  icon: "error",
                  timer: 3000,
                  confirmButtonText: "OK",
                });
              });
            Swal.fire({
              icon: "success",
              title: "Banner Updated Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            onClose();
          } else {
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <EditIcon cursor='pointer' color="teal" onClick={onOpen} />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setFormData({
            name: banner.banner_description,
            title: banner.banner_name,
            images: banner.images.map((img) => ({
              path: img.path,
              file: null,
              id: img.id,
            })),
          });
          setErrors({});
          onClose();
        }}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Update Banner</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex">
            <Box flex="1" pr="5">
              <FormControl mt={3} isRequired isInvalid={errors.title}>
                <FormLabel>Banner Title</FormLabel>
                <Input
                  placeholder="Enter banner title here"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
                {errors.title && (
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={3} isRequired isInvalid={errors.name}>
                <FormLabel>Banner Text</FormLabel>
                <Input
                  placeholder="Enter banner text here"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={3} isInvalid={errors.images}>
                <FormLabel>Banner Images</FormLabel>
                <Box border="1px dashed gray" p={4} borderRadius="md">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50px"
                    cursor="pointer"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <Text>Choose Images</Text>
                  </Box>
                  <Input
                    type="file"
                    multiple
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <Box display="flex" flexWrap="wrap" mt={4}>
                    {formData?.images?.map((image, index) => (
                      <Box
                        key={index}
                        position="relative"
                        m={2}
                        borderRadius="md"
                      >
                        <Image
                          src={
                            image.file
                              ? image.path
                              : `${process.env.REACT_APP_IMG_API}/${image.path}`
                          }
                          alt={`banner-image-${index}`}
                          boxSize="100px"
                          objectFit="cover"
                          borderRadius="md"
                        />
                        <IconButton
                          size="sm"
                          colorScheme="red"
                          aria-label="Remove image"
                          icon={<CloseIcon />}
                          position="absolute"
                          top="0"
                          right="0"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                {errors.images && (
                  <Text color="red.500" mt={2}>
                    {errors.images}
                  </Text>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setFormData({
                  name: banner.banner_description,
                  title: banner.banner_name,
                  images: banner.images.map((img) => ({
                    path: img.path,
                    file: null,
                    id: img.id,
                  })),
                });
                setErrors({});
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default UpdateBannerModal;
