import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  ChakraProvider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { BsThreeDots } from "react-icons/bs";
import {
  SearchAdsService,
  ChangeAddTypeService,
  DeleteAddPostService,
  UpdateAddService,
} from "../../service/service";
import ReactPaginate from "react-paginate";
import AssignAdToAgentModal from "./AssignAdToAgentModal";
import EditAdModal from "./EditAdModal";

function AddsAprovalTable({
  responseData,
  setResponseData,
  totalPages,
  currentPage,
  handlePageChange,
  noData,
  setIsLoading,
  itemsPerPage,
  setTotalPages,
  agentId,
  setNoData,
}) {
  const nav = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();
  const [editData, setEditData] = useState(null);

  const openModal = (id, currentType) => {
    setSelectedId(id);
    setSelectedType(currentType);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedType("");
    setSelectedId(null);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = { id: selectedId, type: selectedType };
    ChangeAddTypeService(payload)
      .then((response) => {
        setIsLoading(false);
        closeModal();
        if (response.status) {
          Swal.fire({
            title: "Success!",
            text: "Ad type updated successfully",
            icon: "success",
            timer: 3000,
            confirmButtonText: "OK",
          });
          // Refresh the table data
          fetchData(currentPage, searchTerm);
        } else {
          Swal.fire("Error!", response.message, "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire("Error!", error.message, "error");
      });
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        DeleteAddPostService({ id })
          .then((response) => {
            setIsLoading(false);
            if (response.status) {
              Swal.fire("Deleted!", "The ad has been deleted.", "success");
              fetchData(currentPage, searchTerm);
            } else {
              setIsLoading(false);
              Swal.fire("Error!", response.message, "error");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            Swal.fire("Error!", error.message, "error");
          });
      }
    });
  };

  const openEditModal = (data) => {
    setEditData(data);
    onEditModalOpen();
  };

  const handleEditSubmit = (updatedData) => {
    setIsLoading(true);
    UpdateAddService(updatedData)
      .then((response) => {
        setIsLoading(false);
        onEditModalClose();
        if (response.status) {
          Swal.fire({
            title: "Success!",
            text: "Ad updated successfully",
            icon: "success",
            timer: 3000,
            confirmButtonText: "OK",
          });
          fetchData(currentPage, searchTerm);
        } else {
          Swal.fire("Error!", response.message, "error");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire("Error!", error.message, "error");
      });
  };

  const fetchData = (page = currentPage, search = searchTerm) => {
    setIsLoading(true);

    SearchAdsService({
      searchText: search,
      per_page: itemsPerPage,
      page:page+1,
      agentId,
    })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setResponseData(result.data.data);
          setTotalPages(result.data.last_page); // Ensure totalPages is updated
          if (result.data.data.length === 0) {
            setNoData("No data to show");
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    fetchData(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    fetchData(currentPage, event.target.value); // Keep the current page when searching
  };

  return (
    <div className="px-10 py-8">
      <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
        <ChakraProvider>
          <Box width="100%" p="5">
            <Stack alignItems={"end"}>
              <InputGroup mb="4" w="30%">
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />}
                />
                <Input
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </Stack>
            {(adminData?.type === 0 || userPermission?.ads?.read) && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Id
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Title
                    </Th>
                    {adminData.type == 0 && (
                      <Th
                        color="black"
                        textTransform={"capitalize"}
                        fontFamily={"Nunito Sans"}
                        fontSize={14}
                      >
                        Agent Name
                      </Th>
                    )}
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Ad Type
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Status
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Remaining Quantity
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {responseData?.map((row, index) => (
                    <Tr key={row.id}>
                      <Td>{row.id}</Td>
                      <Td>{row.name}</Td>
                      {adminData.type == 0 && <Td>{row.assign_user_name}</Td>}
                      <Td textTransform={"capitalize"}>{row.ad_type}</Td>
                      <Td>
                        {row.status === 0
                          ? "Pending"
                          : row.status === 1
                          ? "Approved"
                          : "Rejected"}
                      </Td>
                      <Td>{row.quantity}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            _hover={{ bg: "transparent" }}
                            bg={"transparent"}
                            as={Button}
                          >
                            <BsThreeDots />
                          </MenuButton>
                          <MenuList>
                            {(adminData?.type === 0 ||
                              userPermission?.ads?.read) && (
                              <MenuItem
                                onClick={() =>
                                  nav("/add-details", {
                                    state: { id: row.id },
                                  })
                                }
                              >
                                View Details
                              </MenuItem>
                            )}
                            {/* {(adminData?.type === 0 ||
                              userPermission?.ads?.delete) && (
                              <MenuItem onClick={() => handleDelete(row.id)}>
                                Delete Ad
                              </MenuItem>
                            )} */}
                            {(adminData?.type === 0 ||
                              userPermission?.ads?.update) && (
                              <MenuItem onClick={() => openEditModal(row)}>
                                Update Ad
                              </MenuItem>
                            )}
                            {adminData?.type === 0 && (
                              <>
                                <MenuItem
                                  onClick={() => openModal(row.id, row.ad_type)}
                                >
                                  Choose Type
                                </MenuItem>
                                <MenuItem>
                                  <AssignAdToAgentModal
                                    fetchData={fetchData}
                                    ad_id={row.id}
                                    agentId={row.assignAgent_id}
                                  />
                                </MenuItem>
                              </>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            <Text mt="4">
              Showing {responseData.length} of {responseData.length} rows
            </Text>
          </Box>
          <Modal isCentered isOpen={isModalOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Choose Type</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Select
                  _hover={{ cursor: "pointer" }}
                  placeholder="Select type"
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="seasonal">Seasonal</option>
                  <option value="featured">Featured</option>
                </Select>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="teal" mr={3} onClick={handleSubmit}>
                  Submit
                </Button>
                <Button variant="ghost" onClick={closeModal}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {isEditModalOpen && (
            <EditAdModal
              isOpen={isEditModalOpen}
              onClose={onEditModalClose}
              data={editData}
              fetchData={fetchData}
            />
          )}
        </ChakraProvider>
        {responseData.length === 0 && (
          <div className="flex h-[80px] items-center justify-center text-lg font-bold">
            {noData}
          </div>
        )}
        <div className="pagination w-full bg-whiteGrey px-6">
          <ReactPaginate
            className="flex justify-end items-center gap-x-3 h-[80px]"
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            initialPage={currentPage}
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.94804 19.9968C9.66299 19.6829 9.68874 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.68874 7.80069 9.66299 7.31712 9.94804 7.00317C10.2331 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.2331 20.3108 9.94804 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default AddsAprovalTable;
