import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import { ThreeCircles } from "react-loader-spinner";
import RoleTable from "../../adminComponents/categories/RoleTable";

function CategoriesManagement() {

  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [noData, setNoData] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  function handlePageChange(selectedPage) {
    setCurrentPage(selectedPage.selected);
  }




  const buttonElement =  (
    <div className="flex gap-x-4">
    </div>
  );

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Category Management"}
            headerButton={buttonElement}
            subtitle={"Role Management"}
          />
          <RoleTable
            responseData={responseData}
            setResponseData={setResponseData}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            noData={noData}
            setIsLoading={setIsLoading}
            itemsPerPage={itemsPerPage}
            setTotalPages={setTotalPages}
          />
        </main>
      </div>
    </>
  );
}

export default CategoriesManagement;
