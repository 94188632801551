import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Image,
} from "@chakra-ui/react";
import {
  AddSubCategoryService,
  GetCategoriesList,
  SearchSubcategoryService,
} from "../../service/service"; // Assume these are the API calls
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";

function AddSubCategoryModal({
  setResponseData,
  setTotalPages,
  currentPage,
  itemsPerPage,
  searchText,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    categoryId: "",
    file: null,
  });
  const [errors, setErrors] = useState({});
  const [categories, setCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    // Fetch categories when the modal is opened
    if (isOpen) {
      GetCategoriesList()
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
            setCategories(result.data.data);
          } else {
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error.message);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      const file = files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        setErrors((prev) => ({
          ...prev,
          file: "Only JPG, JPEG, and PNG files are allowed.",
        }));
        return;
      }
      if (file.size > 2 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          file: "Image size must be less than 2MB.",
        }));
        return;
      }
      setErrors((prev) => ({
        ...prev,
        file: null,
      }));
      setFormData((prev) => ({ ...prev, [name]: file }));
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Subcategory name is required.";
    }
    if (!formData.description) {
      errors.description = "Description is required.";
    }
    if (!formData.categoryId) {
      errors.categoryId = "Category is required.";
    }
    if (!formData.file) {
      errors.file = "Image is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      // Call API to save subcategory
      try {
        setIsLoading(true);
        const formDataPayload = new FormData();
        formDataPayload.append("subcategory_name", formData.name);
        formDataPayload.append("description", formData.description);
        formDataPayload.append("category_id", formData.categoryId);
        formDataPayload.append("image", formData.file);

        AddSubCategoryService(formDataPayload).then((result) => {
          if (result.status) {
            SearchSubcategoryService({
              searchText,
              per_page: itemsPerPage,
              page: currentPage,
            })
              .then((response) => {
                if (response.status) {
                  setResponseData(response.data.data);
                  setTotalPages(response.data.last_page);
                } else {
                  Swal.fire({
                    title: "Error!",
                    text: response.message,
                    icon: "error",
                    timer: 3000,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
                console.log(error.message);
                Swal.fire({
                  title: "Error!",
                  text: error.message,
                  icon: "error",
                  timer: 3000,
                  confirmButtonText: "OK",
                });
              });
            setIsLoading(false);

            Swal.fire({
              icon: "success",
              title: "Subcategory Added Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            onClose();
            setFormData({
              name: "",
              description: "",
              categoryId: "",
              file: null,
            });
            setImagePreview(null);
          } else {
            setErrors((prev) => ({
              ...prev,
              name: result.message,
            }));
            setIsLoading(false)
          }
        });
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <Button onClick={onOpen} colorScheme="teal">
        Add Sub Category
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setFormData({
            name: "",
            description: "",
            categoryId: "",
            file: null,
          });
          setImagePreview(null);
          setErrors({});
          setIsLoading(false)
          onClose();
        }}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Add SubCategory</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={errors.categoryId} mt={4}>
                <FormLabel>Category</FormLabel>
                <Select
                  placeholder="Select category"
                  name="categoryId"
                  value={formData.categoryId}
                  onChange={handleChange}
                >
                  {categories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.category}
                    </option>
                  ))}
                </Select>
                {errors.categoryId && (
                  <FormErrorMessage>{errors.categoryId}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl pt={3} isRequired isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Enter subcategory name here"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl
                pt={3}
                isRequired
                isInvalid={errors.description}
                mt={4}
              >
                <FormLabel>Description</FormLabel>
                <Input
                  placeholder="Enter description here"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                />
                {errors.description && (
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isRequired mt="4" isInvalid={errors.file}>
                <FormLabel>Image</FormLabel>
                <Input
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
                <label htmlFor="fileInput">
                  <Box
                    textAlign={"center"}
                    borderWidth="1px"
                    borderRadius="md"
                    p="2"
                    cursor="pointer"
                    borderColor={errors.file ? "red.500" : "gray.200"}
                  >
                    {formData.file ? formData.file.name : "Choose Image"}
                  </Box>
                </label>
                {errors.file && (
                  <FormErrorMessage>{errors.file}</FormErrorMessage>
                )}
              </FormControl>
              {imagePreview && (
                <Image
                  src={imagePreview}
                  alt="Selected Image"
                  boxSize="200px"
                  mt="4"
                  objectFit="cover"
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setFormData({
                  name: "",
                  description: "",
                  categoryId: "",
                  file: null,
                });
                setImagePreview(null);
                setErrors({});
                setIsLoading(false)
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AddSubCategoryModal;
