import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { ChakraProvider, HStack, Stack } from "@chakra-ui/react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Flex,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import AddSubCategoryModal from "./AddSubCategoryModal";
import UpdateSubcategoryModal from "./UpdateSubcategoryModal";
import {
  DeleteSubCategoryService,
  SearchSubcategoryService,
} from "../../service/service";

function SubCategoriesTable({
  responseData,
  setIsLoading,
  setResponseData,
  totalPages,
  currentPage,
  handlePageChange,
  noData,
  setTotalPages,
  itemsPerPage,
}) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData(currentPage, searchText);
  }, [currentPage, searchText]);

  const deleteSubCategoryHandler = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this subcategory?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const formData = { id };
        DeleteSubCategoryService(formData)
          .then((result) => {
            setIsLoading(false);
            if (result.status) {
              fetchData(currentPage, searchText);
              Swal.fire({
                icon: "success",
                title: result.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: result.message,
                icon: "error",
                timer: 3000,
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error.message);
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          });
      }
    });
  };

  const fetchData = (page = currentPage, search = searchText) => {
    setIsLoading(true);

    SearchSubcategoryService({
      searchText: search,
      per_page: itemsPerPage,
      page:page+1,
    })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setResponseData(result.data.data);
          setTotalPages(result.data.last_page); // Ensure totalPages is updated
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    fetchData(currentPage, event.target.value); // Keep the current page when searching
  };

  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  return (
    <div className="px-10 py-8">
      <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
        <ChakraProvider>
          <Box width="100%" p="5">
            <Flex justifyContent="end" mb="4">
              {(adminData?.type === 0 ||
                userPermission?.categories?.create) && (
                <AddSubCategoryModal
                  setResponseData={setResponseData}
                  setTotalPages={setTotalPages}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  searchText={searchText}
                />
              )}
            </Flex>
            <Stack alignItems={"end"}>
              <InputGroup mb="4" w="30%">
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />}
                />
                <Input
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </InputGroup>
            </Stack>
            {(adminData?.type === 0 || userPermission?.categories?.read) && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Sr#
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Category
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Sub Category
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Description
                    </Th>
                    <Th
                      color="black"
                      textTransform={"capitalize"}
                      fontFamily={"Nunito Sans"}
                      fontSize={14}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {responseData.map((row, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{row.category_name}</Td>
                      <Td>{row.subcategory}</Td>
                      <Td>{row.description}</Td>
                      <Td>
                        <HStack>
                          <Stack cursor={"pointer"}>
                            {(adminData?.type === 0 ||
                              userPermission?.categories?.update) && (
                              <UpdateSubcategoryModal
                                setTotalPages={setTotalPages}
                                subCategoryData={row}
                                setResponseData={setResponseData}
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                searchText={searchText}
                              />
                            )}
                          </Stack>
                          <Stack cursor={"pointer"}>
                            {(adminData?.type === 0 ||
                              userPermission?.categories?.delete) && (
                              <MdOutlineDeleteOutline
                                fontSize={20}
                                color="red"
                                onClick={() => deleteSubCategoryHandler(row.id)}
                              />
                            )}
                          </Stack>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            <Text mt="4">
              Showing {responseData.length} of {responseData.length} rows
            </Text>
          </Box>
        </ChakraProvider>
        {responseData.length === 0 && (
          <div className="flex h-[80px] items-center justify-center text-lg font-bold">
            {noData}
          </div>
        )}
        <div className="pagination w-full bg-whiteGrey px-6">
          <ReactPaginate
            className="flex justify-end items-center gap-x-3 h-[80px]"
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            initialPage={currentPage}
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.94804 19.9968C9.66299 19.6829 9.68874 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.68874 7.80069 9.66299 7.31712 9.94804 7.00317C10.2331 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.2331 20.3108 9.94804 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default SubCategoriesTable;
