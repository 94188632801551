import React from "react";
import Swal from "sweetalert2";
import { SearchIcon } from "@chakra-ui/icons";
import { DeleteSubCategoryService, GetSubCategoriesList } from "../../service/service";
import {
  ChakraProvider,
  HStack,
  Stack,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Flex,
} from "@chakra-ui/react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import UpdateBannerModal from "./UpdateBannerModal";
import ViewBannerDetails from "./VieeBannerDetails";
import AddBannerImagesModal from "./AddBannerImagesModal";
import ReactPaginate from "react-paginate";

function BannerTable({
  responseData,
  setIsLoading,
  setResponseData,
  totalPages,
  currentPage,
  handlePageChange,
  noData,
  itemsPerPage,
  setTotalPages
}) {

  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  const displayedItems = responseData?.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  return (
    <div className="px-10 py-8">
      <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-whiteGrey">
        <ChakraProvider>
          <Box width="100%" p="5">
            <Flex justifyContent="end" mb="4">
              {(adminData?.type === 0 || userPermission?.banner?.create) &&
                responseData?.length === 0 && (
                  <AddBannerImagesModal
                    setResponseData={setResponseData}
                    setTotalPages={setTotalPages}
                  />
                )}
            </Flex>
            {/* <Stack alignItems={"end"}>
              <InputGroup mb="4" w="30%">
                <InputLeftElement
                  pointerEvents="none"
                  children={<Icon as={SearchIcon} color="gray.300" />}
                />
                <Input placeholder="Search" />
              </InputGroup>
            </Stack> */}
            {(adminData?.type === 0 || userPermission?.banner?.read) && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th color="black" fontFamily={"Nunito Sans"} fontSize={14}>
                      Title
                    </Th>
                    <Th color="black" fontFamily={"Nunito Sans"} fontSize={14}>
                      Description
                    </Th>
                    <Th color="black" fontFamily={"Nunito Sans"} fontSize={14}>
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {displayedItems?.map((row, index) => (
                    <Tr key={index}>
                      <Td>{row.banner_name}</Td>
                      <Td>{row.banner_description}</Td>
                      <Td>
                        <HStack spacing={3}>
                          {(adminData?.type === 0 ||
                            userPermission?.banner?.update) && (
                            <UpdateBannerModal
                              banner={row}
                              setResponseData={setResponseData}
                              setTotalPages={setTotalPages}
                            />
                          )}

                          <ViewBannerDetails banner={row} />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            <Text mt="4">
              Showing {displayedItems?.length} of {responseData?.length} rows
            </Text>
          </Box>
        </ChakraProvider>
        {/* {responseData.length === 0 && (
          <div className="flex h-[80px] items-center justify-center text-lg font-bold">
            {noData}
          </div>
        )}
        <div className="pagination w-full bg-whiteGrey px-6">
          <ReactPaginate
            className="flex justify-end items-center gap-x-3 h-[80px]"
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            initialPage={currentPage}
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.94801 19.9968C9.66304 19.6829 9.6887 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.6887 7.80069 9.66303 7.31712 9.94801 7.00317C10.233 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.233 20.3108 9.94801 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            onPageChange={handlePageChange}
          />
        </div> */}
      </div>
    </div>
  );
}

export default BannerTable;
