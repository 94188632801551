import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import { ThreeCircles } from "react-loader-spinner";
import orderPic from "../../assets/Orders.svg";
import adsPic from "../../assets/ads.svg";
import pendingOrderPic from "../../assets/pending order.svg";
import completeOrderPic from "../../assets/completed order.svg";
import ApprovedOrderPic from "../../assets/approved adds.svg";
import {
  AgentListService,
  FetchDashboardStatsService,
} from "../../service/service";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  ChakraProvider,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  useDisclosure,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaRegUser, FaUsers } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [moduleName, setmoduleName] = useState("");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    fetchStats();
  }, []);
  const fetchStats = () => {
    setIsLoading(true);
    FetchDashboardStatsService()
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setResponseData(result.data);
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };

  const fetchAgentsHandler = () => {
    AgentListService()
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setAgents(result.data);
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };
  const handleChange = (e) => {
    setSelectedAgent(e.target.value);
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, selectedAgent: "" }));
    }
  };

  const handleSubmit = () => {
    if (moduleName === "Ads") {
      nav("/ads-management", { state: { agentId: selectedAgent } });
    } else {
      nav("/orders-management", { state: { agentId: selectedAgent } });
    }
  };

  const buttonElement = <div className="flex gap-x-4"></div>;
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className=" min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={""}
            showLowerHeader={false}
            headerButton={buttonElement}
            subtitle={"Role Management"}
          />
          <div className="bg-[#F7F7F7] px-10 pb-8">
            <ChakraProvider>
              <Stack fontFamily={"Nunito Sans"}>
                <Stack spacing={0} py={4}>
                  <Text color="#101828" fontWeight="600" fontSize="3xl">
                    Welcome back, {adminData?.name}
                  </Text>
                  <Text color="#475467">
                    Track, manage and forecast your customers and orders.
                  </Text>
                </Stack>
                <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing="30px">
                  <Stack
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    onClick={() => nav("/customers-management")}
                  >
                    <HStack spacing={6}>
                      <FaUsers fontSize={30} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Total Customers
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.totalCustomer}
                    </Text>
                  </Stack>

                  <Stack
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    onClick={() => {
                      if (adminData.type === 0) {
                        setmoduleName("Ads");
                        fetchAgentsHandler();
                        onOpen();
                      } else {
                        nav("/ads-management");
                      }
                    }}
                    className="dashboard-card"
                  >
                    <HStack spacing={6}>
                      <Img className="order-pic" w={8} src={adsPic} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Total Ads
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.totalAds}
                    </Text>
                  </Stack>

                  <Stack
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    className="dashboard-card"
                  >
                    <HStack spacing={6}>
                      <Img className="order-pic" w={8} src={ApprovedOrderPic} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Approved Ads
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.adsApprove}
                    </Text>
                  </Stack>

                  <Stack
                    className="dashboard-card"
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    onClick={() => {
                      if (adminData.type === 0) {
                        setmoduleName("Orders");
                        fetchAgentsHandler();
                        onOpen();
                      } else {
                        nav("/orders-management");
                      }
                    }}
                  >
                    <HStack spacing={6}>
                      <Img className="order-pic" w={8} src={orderPic} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Total Orders
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.totalOrder}
                    </Text>
                  </Stack>

                  <Stack
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    className="dashboard-card"
                  >
                    <HStack spacing={6}>
                      <Img className="order-pic" w={8} src={completeOrderPic} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Completed Orders
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.completeOrder}
                    </Text>
                  </Stack>

                  <Stack
                    bg="white"
                    borderRadius="12px"
                    boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
                    border="1px solid #EAECF0"
                    justifyContent={"center"}
                    alignItems="center"
                    py={5}
                    spacing={8}
                    color="#101828"
                    _hover={{ bg: "teal", color: "white", cursor: "pointer" }}
                    className="dashboard-card"
                  >
                    <HStack spacing={6}>
                      <Img className="order-pic" w={8} src={pendingOrderPic} />
                      <Text
                        fontWeight="600"
                        fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                      >
                        {" "}
                        Pending Orders
                      </Text>
                    </HStack>
                    <Text
                      fontWeight="bold"
                      fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                    >
                      {responseData?.pendingOrder}
                    </Text>
                  </Stack>
                </SimpleGrid>
              </Stack>
              <Modal
                isCentered
                isOpen={isOpen}
                onClose={() => {
                  setSelectedAgent("");
                  onClose();
                }}
                size="md"
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader color="#4D5454">Choose Agent</ModalHeader>
                  <Divider />
                  <ModalCloseButton />
                  <ModalBody display="flex" flexDirection="column">
                    <Box flex="1" pr="5">
                      <FormControl isRequired isInvalid={errors.selectedAgent}>
                        <FormLabel>Select Agent</FormLabel>
                        <Select
                          placeholder="Select agent"
                          value={selectedAgent}
                          onChange={handleChange}
                        >
                          {agents?.map((agent) => (
                            <option key={agent.id} value={agent.id}>
                              {agent.name}
                            </option>
                          ))}
                        </Select>
                        {errors.selectedAgent && (
                          <FormErrorMessage>
                            {errors.selectedAgent}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="gray"
                      mr="3"
                      onClick={() => {
                        setSelectedAgent("");
                        onClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      isLoading={isLoading}
                      colorScheme="teal"
                      isDisabled={!selectedAgent}
                      onClick={handleSubmit}
                    >
                      See {moduleName}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </ChakraProvider>
          </div>
        </main>
      </div>
    </>
  );
}

export default Dashboard;
