import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  SimpleGrid,
  Text,
  Stack,
  Button,
  HStack,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ApproveRejctOrderService,
  FetchSingleOrderService,
} from "../../service/service";
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";

const OrderDetails = () => {
  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const { state } = useLocation();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState();
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsLoading(true);
    FetchSingleOrderService({ order_id: state?.id })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setOrderData(result?.data[0]);
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  }, [state]);

  const handleApprove = () => {
    Swal.fire({
      title: `Are you sure you want to approve this order?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, approve it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        ApproveRejctOrderService({
          order_id: orderData.order_id,
          status: "approve",
        })
          .then((response) => {
            if (response.status) {
              setIsLoading(false);
              nav("/orders-management");
              Swal.fire(`Approved!`, `The order has been approved.`, "success");
            } else {
              setIsLoading(false);
              Swal.fire({
                title: "Error!",
                text: response.message,
                icon: "error",
                timer: 3000,
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          });
      }
    });
  };

  const handleReject = () => {
    onOpen();
  };

  const handleRejectConfirm = () => {
    setIsLoading(true);
    setError("");
    ApproveRejctOrderService({
      order_id: orderData?.order_id,
      status: "reject",
      reason,
    })
      .then((response) => {
        if (response.status) {
          onClose();
          nav("/orders-management");
          setIsLoading(false);
          Swal.fire(`Rejected!`, `The order has been rejected.`, "success");
        } else {
          setIsLoading(false);
          setError(response.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <Box className="px-10 py-3">
        <Box width="100%" p="5">
          <SimpleGrid columns={[1, null, 2]} spacing="30px" mb="4">
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Buyer Name
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.customer_name}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Buyer Address
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.customer_address}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Buyer Mobile
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.customer_mobile}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Order Phone No
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.order_phone_number}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Order City
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.order_city}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Order State
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.order_district}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Order Address
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.order_address}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Quantity
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.quantity}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Per Unit Amount
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>
                  {orderData?.ad_rate} {orderData?.ad_unit}
                </Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Total Order Value
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>
                  {orderData?.total_amount} {orderData?.unit}
                </Text>
              </Box>
            </Stack>
          </SimpleGrid>
          <Text
            my={6}
            fontSize="2xl"
            fontFamily={"Nunito Sans"}
            fontWeight="bold"
          >
            Ad Details
          </Text>
          <SimpleGrid columns={[1, null, 2]} spacing="30px" mb="4">
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Ad Name
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.ad_name}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Ad Description
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.ad_description}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Seller Name
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.ad_customer_name}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Seller Mobile
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.ad_customer_mobile}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Ad Id
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{orderData?.ad_id}</Text>
              </Box>
            </Stack>
          </SimpleGrid>
          {(adminData?.type === 0 || userPermission?.order?.update) &&
            orderData?.status === "pending" && (
              <Stack fontFamily={"Nunito sans"} alignItems={"end"} mt={20}>
                <HStack>
                  <Button
                    color="white"
                    bg="#C80000"
                    w="150px"
                    _hover={{ bg: "#C80000" }}
                    rounded={"3xl"}
                    onClick={handleReject} // Reject
                  >
                    Reject
                  </Button>
                  <Button
                    color="white"
                    bg="#338B35"
                    w="150px"
                    _hover={{ bg: "#338B35" }}
                    rounded={"3xl"}
                    onClick={handleApprove} // Approve
                  >
                    Approve
                  </Button>
                </HStack>
              </Stack>
            )}
        </Box>
      </Box>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setReason();
          setError();
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for rejection"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            {error && (
              <Text color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                setReason();
                setError();
                onClose();
              }}
            >
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleRejectConfirm}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default OrderDetails;
