import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import Swal from "sweetalert2";
import CustomersTable from "../../adminComponents/customers/CustomersTable";
import { ThreeCircles } from "react-loader-spinner";

function CustomersManagement() {

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [noData, setNoData] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  function handlePageChange(selectedPage) {
    setCurrentPage(selectedPage.selected);
  }


  const buttonElement = (
    <div className="flex gap-x-4"></div>
  );

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className="bg-[#f7f7f7] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Customers"}
            headerButton={buttonElement}
            subtitle={"User Management"}
          />

          <CustomersTable
            userData={userData}
            setUserData={setUserData}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            noData={noData}
            itemsPerPage={itemsPerPage}
          />
        </main>
      </div>
    </>
  );
}

export default CustomersManagement;
