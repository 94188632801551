import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Image,
  Text,
  Button,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import { ThreeCircles } from "react-loader-spinner";

function ViewBannerDetails({ banner }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    title: "",
    images: [],
  });

  useEffect(() => {
    if (banner) {
      setFormData({
        name: banner.banner_description,
        title: banner.banner_name,
        images: banner.images.map((img) => ({
          path: img.path,
          file: null,
          id: img.id,
        })),
      });
    }
  }, [banner]);

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <ViewIcon cursor={'pointer'} color="teal" onClick={onOpen} />
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">View Banner Details</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex">
            <Box flex="1" pr="5">
              <Box mt={3}>
                <Text fontWeight="bold">Banner Title</Text>
                <Text mt={3}>{formData.title}</Text>
              </Box>
              <Box mt={3}>
                <Text fontWeight="bold">Banner Text</Text>
                <Text mt={3}>{formData.name}</Text>
              </Box>
              <Box mt={3}>
                <Text fontWeight="bold">Banner Images</Text>
                <Box  display="flex" flexWrap="wrap" mt={4}>
                  {formData?.images?.map((image, index) => (
                    <Box
                      key={index}
                      position="relative"
                      m={2}
                      borderRadius="md"
                    >
                      <Image
                        src={`${process.env.REACT_APP_IMG_API}/${image.path}`}
                        alt={`banner-image-${index}`}
                        boxSize="300px"
                        objectFit="cover"
                        borderRadius="md"
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="teal" onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ViewBannerDetails;
