import React, { useState } from "react";
import LoginHeader from "../../adminComponents/LoginHeader/LoginHeader";
import CreateNewPassword from "../../adminComponents/ResetComponents/CreateNewPassword";

function ResetPassword() {
  const [step, setStep] = useState(0);
  const [emailData, setEmailData] = useState({});

  return (
    <>
      <LoginHeader />
      <div className="flex justify-center items-center">
        <CreateNewPassword emailData={emailData} />
      </div>
    </>
  );
}

export default ResetPassword;
