import React, { useState } from "react";
import { LoginAdmin } from "../../service/service";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/agri.jpg";
import logo from "../../assets/logo.png";

function Login() {

  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) {
      newErrors.email = "Email is required.";
    }

    if (!formData.password) {
      newErrors.password = "Password is required.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setloading(true);
      LoginAdmin(formData)
        .then((result) => {
          // console.log(result);
          if (result.status) {
            localStorage.setItem("adminToken", result.token);
            localStorage.setItem("adminData", JSON.stringify(result.data));
            const jsonString = result.data?.permissions
            const parsedPermissions = jsonString && JSON.parse(jsonString);
            localStorage.setItem("userPermissions", JSON.stringify(parsedPermissions));
            setFormData({
              email: "",
              password: "",
            });
            setErrorMsg("");
            setloading(false);
            navigate("/dashboard");
          } else {
            setErrorMsg(result.message);
            setloading(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setErrorMsg(error.message);
          setloading(false);
        });
    }
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="flex h-screen">
      <div className="w-1/2 hidden lg:block">
        <img
          src={img}
          alt="Login page"
          className="h-full w-full object-cover"
        />
      </div>
      <div className="w-full lg:w-1/2 overflow-y-auto">
        <div className="px-2 xs:px-4 sm:px-12 pt-[20px]">
          <div className="admin-logo-div w-[100px] mx-auto mb-[132px]">
            <img src={logo} alt="inside chassidus" />
          </div>
          <div className="max-w-[611px] mx-auto px-2 xs:px-0 lg:px-14">
            <div className="text-2xl font-bold text-[#4D5454] leading-[28px] mb-2">
              Hi, Welcome Back!
            </div>
            <div className="text-2xl text-[grey] text-[18px] leading-[28px] mb-6">
              Enter your details sign in to your account.
            </div>
            <form className="space-y-[14px]" onSubmit={handleSubmit}>
              {/* Email input */}
              <div className="h-[70px] rounded-lg bg-[#D2D6DC] border-[2px] border-[#CED4DA] bg-opacity-[0.4] pt-2 px-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-normal leading-5 text-[grey]"
                >
                  Email address
                </label>
                <div className="">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="block w-full bg-transparent outline-none border-0 py-1 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                  />
                </div>
              </div>
              {errors.email && (
                <p className="text-red-500 px-3">{errors.email}</p>
              )}

              {/* Password input */}
              <div className="h-[70px] rounded-lg border-[2px] border-[#CED4DA] bg-[#D2D6DC] bg-opacity-[0.4] pt-2 px-4">
                <label
                  htmlFor="password"
                  className="block text-sm font-normal leading-5 text-[grey]"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="block w-full bg-transparent outline-none border-0 py-1 text-black focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                  />
                  <span
                    className="absolute top-0 right-0 cursor-pointer"
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>
              {errors.password && (
                <p className="text-red-500 px-3">{errors.password}</p>
              )}
              {errorMsg !== "" && (
                <p className="text-red-500 px-3">{errorMsg}</p>
              )}

              {/* Submit button */}
              <div className="pt-[10px]">
                <button
                  type="submit"
                  disabled={loading}
                  className="flex w-full h-14 items-center justify-center rounded-md bg-primaryDark hover:bg-opacity-[0.9] focus:bg-primtext-primaryDark px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                >
                  Log In
                </button>
              </div>
            </form>
            <hr className="mt-6 h-[1px] bg-[#D2D6DC]" />
            <div className="text-center py-2">
              <Link
                to="/admin/forgot-password"
                className="text-primaryDark text-lg font-semibold"
              >
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
