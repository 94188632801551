import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import { ThreeCircles } from "react-loader-spinner";
import SubCategoriesTable from "../../adminComponents/subcategories/SubCategoriesTable";

function SubCategoryManagement() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [noData, setNoData] = useState("");
  const [currentPage, setCurrentPage] = useState(0); // Initialize to 1
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  function handlePageChange(selectedPage) {
    setCurrentPage(selectedPage.selected);
  }

  const buttonElement = <div className="flex gap-x-4"></div>;

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className="bg-[#D2D6DC] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Subcategory Management"}
            headerButton={buttonElement}
            subtitle={"Role Management"}
          />
          <SubCategoriesTable
            responseData={responseData}
            setResponseData={setResponseData}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            noData={noData}
            setTotalPages={setTotalPages}
            setIsLoading={setIsLoading}
            itemsPerPage={itemsPerPage}
          />
        </main>
      </div>
    </>
  );
}

export default SubCategoryManagement;
