import React, { useState, useRef, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Img,
  SimpleGrid,
  Text,
  Stack,
  HStack,
  Button,
  Flex,
  useDisclosure,
  ModalFooter,
  Textarea,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdFullscreen } from "react-icons/md";
import {
  ApproveRejctAddPostService,
  GetSingleAddPost,
} from "../../service/service";
import Swal from "sweetalert2";
import { ThreeCircles } from "react-loader-spinner";

const MediaItem = ({ src, type, onFullScreen }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <Box
      width="300px"
      height="400px"
      overflow="hidden"
      position="relative"
      borderRadius="xl"
      bg="gray.100"
      role="group"
      _hover={{ bg: "gray.200" }}
    >
      {type === "image" ? (
        <Img
          src={src}
          height="100%"
          width="100%"
          alt="Ad media"
          objectFit="contain"
        />
      ) : (
        <Box
          as="video"
          src={src}
          ref={videoRef}
          height="100%"
          width="100%"
          borderRadius="xl"
          objectFit="contain"
          controls
        />
      )}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display="none"
        _groupHover={{ display: "block" }}
        cursor="pointer"
        onClick={onFullScreen}
      >
        <MdFullscreen size="2em" color="black" />
      </Box>
    </Box>
  );
};

const AddDetails = () => {
  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const { state } = useLocation();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addData, setAddData] = useState();
  const [reason, setReason] = useState("");
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFullScreenOpen,
    onOpen: onFullScreenOpen,
    onClose: onFullScreenClose,
  } = useDisclosure();
  const [fullScreenSrc, setFullScreenSrc] = useState("");
  const [fullScreenType, setFullScreenType] = useState("");

  useEffect(() => {
    setIsLoading(true);
    GetSingleAddPost({ id: state?.id })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setAddData(result?.data[0]);
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  }, [state]);

  const handleApprove = () => {
    Swal.fire({
      title: `Are you sure you want to Approve this ad post?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, approve it!`,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        ApproveRejctAddPostService({ id: addData.id, status: 1 })
          .then((response) => {
            if (response.status) {
              setIsLoading(false);
              nav("/ads-management");
              Swal.fire(
                `Approved!`,
                `The ad post has been approved.`,
                "success"
              );
            } else {
              setIsLoading(false);
              Swal.fire({
                title: "Error!",
                text: response.message,
                icon: "error",
                timer: 3000,
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          });
      }
    });
  };

  const handleReject = () => {
    onOpen();
  };

  const handleRejectConfirm = () => {
    setIsLoading(true);
    setError("");
    ApproveRejctAddPostService({ id: addData.id, status: 2, reason })
      .then((response) => {
        if (response.status) {
          onClose();
          nav("/ads-management");
          setIsLoading(false);
          Swal.fire(`Rejected!`, `The Ad post has been rejected.`, "success");
        } else {
          setIsLoading(false);
          setError(response.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  };

  const openFullScreen = (src, type) => {
    setFullScreenSrc(src);
    setFullScreenType(type);
    onFullScreenOpen();
  };

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <Box className="px-10 py-3">
        <Box width="100%" p="5">
          <Text fontSize="2xl" fontWeight="bold" mb="4">
            Media
          </Text>
          <Flex flexWrap="wrap" gap="30px" my="8">
            {addData?.images?.map((media) => (
              <MediaItem
                key={media.id}
                src={`${process.env.REACT_APP_IMG_API}/${media.path}`}
                type={media.type}
                onFullScreen={() => openFullScreen(`${process.env.REACT_APP_IMG_API}/${media.path}`, media.type)}
              />
            ))}
          </Flex>
          <SimpleGrid columns={[1, null, 2]} spacing="20px" mb="4">
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Title
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.name}</Text>
              </Box>
            </Stack>
            {addData?.description && (
              <Stack>
                <Text
                  fontSize="md"
                  fontFamily={"Nunito Sans"}
                  fontWeight="bold"
                >
                  Description
                </Text>
                <Box p="4" bg="gray.100" borderRadius="md">
                  <Text>{addData?.description}</Text>
                </Box>
              </Stack>
            )}
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Category
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.category_name}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Sub Category
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.subcategory_name}</Text>
              </Box>
            </Stack>

            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Remaining Quantity
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.quantity}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Total Quantity
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.total_quantity}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Total Cost
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.total_cost}</Text>
              </Box>
            </Stack>
            {/* <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Remaining Cost
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.remaning_cost}</Text>
              </Box>
            </Stack> */}
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Seller Name
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.seller_name} </Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Seller Phone No
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.seller_phone_number} </Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Per unit amount
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>
                  {addData?.rate} {addData?.unit}
                </Text>
              </Box>
            </Stack>

            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                Address
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.address}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                State
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.district}</Text>
              </Box>
            </Stack>
            <Stack>
              <Text fontSize="md" fontFamily={"Nunito Sans"} fontWeight="bold">
                City
              </Text>
              <Box p="4" bg="gray.100" borderRadius="md">
                <Text>{addData?.city}</Text>
              </Box>
            </Stack>
          </SimpleGrid>
          {(adminData?.type === 0 || userPermission?.ads?.update) &&
            addData?.status === 0 && (
              <Stack fontFamily={"Nunito sans"} alignItems={"end"} mt={20}>
                <HStack>
                  <Button
                    color="white"
                    bg="#C80000"
                    w="150px"
                    _hover={{ bg: "#C80000" }}
                    rounded={"3xl"}
                    onClick={handleReject} // Reject
                  >
                    Reject
                  </Button>
                  <Button
                    color="white"
                    bg="#338B35"
                    w="150px"
                    _hover={{ bg: "#338B35" }}
                    rounded={"3xl"}
                    onClick={handleApprove} // Approve
                  >
                    Approve
                  </Button>
                </HStack>
              </Stack>
            )}
        </Box>
      </Box>
      <Modal
        isCentered
        isOpen={isFullScreenOpen}
        onClose={onFullScreenClose}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton color="black" />
          <ModalBody display="flex" justifyContent="center" alignItems="center">
            {fullScreenType === "image" ? (
              <Img src={fullScreenSrc} maxH="90vh" maxW="90vw" objectFit="contain" />
            ) : (
              <Box
                as="video"
                src={fullScreenSrc}
                controls
                maxH="90vh"
                maxW="90vw"
                borderRadius="xl"
                objectFit="contain"
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setReason("");
          setError("");
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Reason</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter reason for rejection"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
            {error && (
              <Text color="red.500" mt={2}>
                {error}
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                setReason("");
                setError("");
                onClose();
              }}
            >
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleRejectConfirm}>
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default AddDetails;
