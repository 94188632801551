import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import UserTable from "../../adminComponents/UserManagement/UserTabel";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";

function UserManagement() {
  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-whiteGrey">
        <DashboardSideNav />
        <main className="bg-[#f7f7f7] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Users"}
            headerButton={<div className="flex gap-x-4"></div>}
            subtitle={"User Management"}
          />

          <UserTable
            setTotalPages={setTotalPages}
            userData={userData}
            setIsLoading={setIsLoading}
            setUserData={setUserData}
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            noData={noData}
            itemsPerPage={itemsPerPage}
          />
        </main>
      </div>
    </>
  );
}

export default UserManagement;
