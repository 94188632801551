import React from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./adminPages/LoginPage/Login";
import ResetPassword from "./adminPages/RestetPassword/ResetPassword";
import PrivateRoute from "./route/PrivateRoute";
import UserManagement from "./adminPages/Dashboard/UserManagement";
import Unauthorized from "./adminPages/unauth/Unuth";
import SubCategoryManagement from "./adminPages/Dashboard/SubCategoryManagement";
import CustomersManagement from "./adminPages/Dashboard/CustomersManagement";
import AddsManagement from "./adminPages/Dashboard/AddsManagement";
import AddDetailPage from "./adminPages/Dashboard/AddDetailPage";
import CategoriesManagement from "./adminPages/Dashboard/CategoriesManagement";
import OrdersManagement from "./adminPages/Dashboard/OrdersManagement";
import BannerManagement from "./adminPages/Dashboard/BannerManagement";
import Dashboard from "./adminPages/Dashboard/Dashboard";
import ForgotPassword from "./adminComponents/ResetComponents/ForgotPassword";
import OrderDetailsPage from "./adminPages/Dashboard/OrderDetailsPage";
import PrivacyPolicy from "./adminComponents/privacy-policy/PrivacyPolicy";
import RemoveCustomer from "./adminComponents/otp/RemoveCustomer";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />

        <Route
          exact
          path="/admin/reset-password/:token"
          element={<ResetPassword />}
        />
        <Route
          exact
          path="/admin/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/remove-customer"
          element={<RemoveCustomer />}
        />
        <Route exact path="/" element={<Login />} />

        <Route
          exact
          path="/user-management"
          element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/categories"
          element={
            <PrivateRoute>
              <CategoriesManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/sub-categories"
          element={
            <PrivateRoute>
              <SubCategoryManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/customers-management"
          element={
            <PrivateRoute>
              <CustomersManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/ads-management"
          element={
            <PrivateRoute>
              <AddsManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/add-details"
          element={
            <PrivateRoute>
              <AddDetailPage />
            </PrivateRoute>
          }
        />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          exact
          path="/orders-management"
          element={
            <PrivateRoute>
              <OrdersManagement />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/order-details"
          element={
            <PrivateRoute>
              <OrderDetailsPage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/banner-management"
          element={
            <PrivateRoute>
              <BannerManagement />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
