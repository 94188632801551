import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  ChakraProvider,
  Select,
  SimpleGrid,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  IconButton,
  Image,
  Flex,
} from "@chakra-ui/react";
import { State, City } from "country-state-city";
import { DeleteIcon, CloseIcon } from "@chakra-ui/icons";
import {
  GetCategoriesList,
  GetSubCategoriesByCategoryIdList,
  UpdateAddService,
} from "../../service/service";
import { ThreeCircles } from "react-loader-spinner";

function EditAdModal({
  isOpen,
  onClose,
  data,
  fetchData, // Added fetchData as a prop
}) {
  // console.log("🚀 ~ data:", data)
  const [isLoading, setisLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubCategoriesList] = useState([]);
  const [errors, setErrors] = useState({});
  const [unitOptions, setUnitOptions] = useState([]); // New state for unit options
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedVideoId, setDeletedVideoId] = useState(null); // State for deleted video ID
  // console.log("🚀 ~ deletedVideoId:", deletedVideoId)
  const [newImages, setNewImages] = useState([]);
  const [newVideo, setNewVideo] = useState(null); // State for new video

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    description: "",
    address: "",
    city: "",
    district: "",
    category_id: "",
    subcategory_id: "",
    stateName: "",
    material_type: "", // New field for material type
    unit: "", // New field for unit
    quantity: data.total_quantity || 0, // Set initial quantity
    rate: data.rate || 0, // Set initial rate
    images: data.images.filter((img) => img.type === "image") || [], // Set initial images
    video: data.images.find((img) => img.type === "video") || null, // Set initial video
  });

  useEffect(() => {
    setisLoading(true);
    const fetchData = async () => {
      try {
        const categoriesRes = await GetCategoriesList();
        setCategoriesList(categoriesRes.data.data);

        if (data.category_id) {
          const subcategoriesRes = await GetSubCategoriesByCategoryIdList(
            data.category_id
          );
          setSubCategoriesList(subcategoriesRes.data);
        }

        const states = State.getStatesOfCountry("PK");
        setStateList(states);

        const filteredState =
          states &&
          states.filter(
            (state) => state.name.toLowerCase() === data.district.toLowerCase()
          );

        const cities = City.getCitiesOfState(
          "PK",
          filteredState && filteredState[0]?.isoCode
        );
        setCityList(cities);

        const filteredCity =
          cities &&
          cities.filter(
            (city) => city.name.toLowerCase() === data.city.toLowerCase()
          );

        setFormData((prev) => ({
          ...prev,
          id: data?.id || "",
          name: data?.name || "",
          description: data?.description || "",
          address: data?.address || "",
          city: (filteredCity && filteredCity[0]?.name) || "",
          district: (filteredState && filteredState[0]?.isoCode) || "",
          stateName: (filteredState && filteredState[0]?.name) || "",
          category_id: data.category_id || "",
          subcategory_id: data.subcategory_id || "",
          material_type: data.material_type || "", // Set initial material type
          unit: data.unit || "", // Set initial unit
          quantity: data.total_quantity || 0, // Set initial quantity
          rate: data.rate || 0, // Set initial rate
          images: data.images.filter((img) => img.type === "image") || [], // Set initial images
          video: data.images.find((img) => img.type === "video") || null, // Set initial video
        }));

        // Set unit options based on the initial material type
        if (data.material_type === "raw") {
          setUnitOptions(["Ton", "Mann", "Kg"]);
        } else if (data.material_type === "liquid") {
          setUnitOptions(["Litre", "Millilitre(ml)"]);
        }

        setisLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setisLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    const stateName = e.target.options[e.target.selectedIndex].text;
    setFormData((prev) => ({ ...prev, district: stateId, stateName }));
    const cities = City.getCitiesOfState("PK", stateId);
    setCityList(cities);
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setFormData((prev) => ({ ...prev, city: cityId }));
  };

  const handleCategoryChange = async (e) => {
    const categoryId = e.target.value;
    setFormData((prev) => ({
      ...prev,
      category_id: categoryId,
      subcategory_id: "",
    }));
    try {
      const subcategoriesRes = await GetSubCategoriesByCategoryIdList(
        categoryId
      );
      setSubCategoriesList(subcategoriesRes.data);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  const handleSubCategoryChange = (e) => {
    const subcategoryId = e.target.value;
    setFormData((prev) => ({ ...prev, subcategory_id: subcategoryId }));
  };

  const handleMaterialTypeChange = (e) => {
    const material_type = e.target.value;
    setFormData((prev) => ({ ...prev, material_type, unit: "" }));

    // Update unit options based on material type
    if (material_type === "raw") {
      setUnitOptions(["Ton", "Mann", "Kg"]);
    } else if (material_type === "liquid") {
      setUnitOptions(["Litre", "Millilitre(ml)"]);
    } else {
      setUnitOptions([]);
    }
  };

  const handleUnitChange = (e) => {
    const unit = e.target.value;
    setFormData((prev) => ({ ...prev, unit }));
  };

  const handleQuantityChange = (value) => {
    setFormData((prev) => ({ ...prev, quantity: value }));
  };

  const handleRateChange = (value) => {
    setFormData((prev) => ({ ...prev, rate: value }));
  };

  const handleImageDelete = (imageId) => {
    setDeletedImages((prev) => [...prev, imageId]);
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((image) => image.id !== imageId),
    }));
  };

  const handleNewImageDelete = (index) => {
    setNewImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleVideoDelete = () => {
    setDeletedVideoId(formData.video.id);
    setFormData((prev) => ({ ...prev, video: null }));
    setNewVideo(null);
  };

  const handleNewVideoDelete = () => {
    setNewVideo(null);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];
    let sizeError = false;

    files.forEach((file) => {
      if (file.size <= 4 * 1024 * 1024) {
        validFiles.push(file);
      } else {
        sizeError = true;
      }
    });

    if (sizeError) {
      setErrors((prev) => ({ ...prev, images: "Each image must be less than 4MB." }));
    } else {
      setErrors((prev) => ({ ...prev, images: "" }));
    }

    if (formData.images.length + newImages.length + validFiles.length > 5) {
      setErrors((prev) => ({ ...prev, images: "You cannot add more than 5 images." }));
      return;
    }

    setNewImages((prev) => [...prev, ...validFiles]);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        setNewVideo(file);
        // setDeletedVideoId(formData.video ? formData.video.id : null);
        setFormData((prev) => ({ ...prev, video: null }));
        setErrors((prev) => ({ ...prev, video: "" }));
      } else {
        setErrors((prev) => ({ ...prev, video: "Video must be less than 5MB." }));
      }
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Ad name is required.";
    if (!formData.address) newErrors.address = "Address is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.district) newErrors.district = "District is required.";
    if (!formData.category_id) newErrors.category_id = "Category is required.";
    if (!formData.subcategory_id)
      newErrors.subcategory_id = "Subcategory is required.";
    if (!formData.material_type)
      newErrors.material_type = "Material type is required.";
    if (!formData.unit) newErrors.unit = "Unit is required.";
    if (formData.quantity < 0)
      newErrors.quantity = "Quantity cannot be negative.";
    if (formData.rate < 0) newErrors.rate = "Rate cannot be negative.";
    if (formData.images?.length + newImages?.length > 5) {
      newErrors.images = "You cannot add more than 5 images.";
    }
    if (newVideo && newVideo.type.split("/")[0] !== "video") {
      newErrors.video = "Invalid file type. Only video files are allowed.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      setisLoading(true);
      try {
        const payload = new FormData();
        payload.append("id", formData.id);
        payload.append("name", formData.name);
        payload.append("description", formData.description);
        payload.append("address", formData.address);
        payload.append("city", formData.city);
        payload.append("district", formData.stateName);
        payload.append("category_id", formData.category_id);
        payload.append("subcategory_id", formData.subcategory_id);
        payload.append("material_type", formData.material_type);
        payload.append("unit", formData.unit);
        payload.append("quantity", formData.quantity);
        payload.append("rate", formData.rate);
        deletedImages.forEach((id, index) =>
          payload.append(`ad_images_id[${index}]`, id)
        );
        newImages.forEach((image, index) =>
          payload.append(`ad_images[${index}]`, image)
        );
        if (newVideo) {
          payload.append("video", newVideo);
        }
        if (deletedVideoId) {
          payload.append("video_id", deletedVideoId);
        }

        // Debug: Log formData entries
        // for (let pair of payload.entries()) {
        //   console.log(pair[0] + ", " + pair[1]);
        // }

        const response = await UpdateAddService(payload);
        if (response.status) {
          onClose();
          fetchData(); // Call fetchData after updating the ad
          Swal.fire({
            title: "Success!",
            text: "Ad updated successfully",
            icon: "success",
            timer: 3000,
            confirmButtonText: "OK",
          });
          setisLoading(false);
        } else {
          Swal.fire("Error!", response.message, "error");
        }
      } catch (error) {
        setisLoading(false);
        Swal.fire("Error!", error.message, "error");
      }
    }
  };

  return (
    <ChakraProvider>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Ad</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" flexDirection="column">
            <Box flex="1" p="5">
              <SimpleGrid columns={[1, null, 2]} spacing="20px" mb="4">
                <FormControl isInvalid={errors.name}>
                  <FormLabel>Ad Name</FormLabel>
                  <Input
                    placeholder="Enter ad name here"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && (
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.category_id}>
                  <FormLabel>Category</FormLabel>
                  <Select
                    placeholder="Select category"
                    onChange={handleCategoryChange}
                    value={formData.category_id}
                  >
                    {categoriesList?.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.category}
                      </option>
                    ))}
                  </Select>
                  {errors.category_id && (
                    <FormErrorMessage>{errors.category_id}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.subcategory_id}>
                  <FormLabel>SubCategory</FormLabel>
                  <Select
                    placeholder="Select subcategory"
                    onChange={handleSubCategoryChange}
                    value={formData.subcategory_id}
                  >
                    {subcategoriesList?.map((subcategory) => (
                      <option key={subcategory.id} value={subcategory.id}>
                        {subcategory.subcategory}
                      </option>
                    ))}
                  </Select>
                  {errors.subcategory_id && (
                    <FormErrorMessage>{errors.subcategory_id}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Input
                    placeholder="Enter description here"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl isInvalid={errors.address}>
                  <FormLabel>Address</FormLabel>
                  <Input
                    placeholder="Enter address here"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <FormErrorMessage>{errors.address}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.district}>
                  <FormLabel>Province/State</FormLabel>
                  <Select
                    placeholder="Select state/province"
                    onChange={handleStateChange}
                    value={formData.district}
                  >
                    {stateList.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </Select>
                  {errors.district && (
                    <FormErrorMessage>{errors.district}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.city}>
                  <FormLabel>City</FormLabel>
                  <Select
                    placeholder="Select city"
                    onChange={handleCityChange}
                    value={formData.city}
                  >
                    {cityList.map((city) => (
                      <option key={city.isoCode} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </Select>
                  {errors.city && (
                    <FormErrorMessage>{errors.city}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.material_type}>
                  <FormLabel>Material Type</FormLabel>
                  <Select
                    placeholder="Select material type"
                    onChange={handleMaterialTypeChange}
                    value={formData.material_type.toLowerCase()}
                  >
                    <option value="raw">Raw</option>
                    <option value="liquid">Liquid</option>
                  </Select>
                  {errors.material_type && (
                    <FormErrorMessage>{errors.material_type}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.unit}>
                  <FormLabel>Unit</FormLabel>
                  <Select
                    placeholder="Select unit"
                    onChange={handleUnitChange}
                    value={formData.unit}
                  >
                    {unitOptions.map((unit) => (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    ))}
                  </Select>
                  {errors.unit && (
                    <FormErrorMessage>{errors.unit}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.quantity}>
                  <FormLabel>Quantity</FormLabel>
                  <NumberInput
                    min={0}
                    value={formData.quantity}
                    onChange={handleQuantityChange}
                  >
                    <NumberInputField name="quantity" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {errors.quantity && (
                    <FormErrorMessage>{errors.quantity}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={errors.rate}>
                  <FormLabel>Rate</FormLabel>
                  <NumberInput
                    min={0}
                    value={formData.rate}
                    onChange={handleRateChange}
                  >
                    <NumberInputField name="rate" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  {errors.rate && (
                    <FormErrorMessage>{errors.rate}</FormErrorMessage>
                  )}
                </FormControl>
              </SimpleGrid>
              <FormControl isInvalid={errors.images}>
                <FormLabel>Images</FormLabel>
                <Button
                  onClick={() => document.getElementById("imageInput").click()}
                  mb="4"
                  w={"200px"}
                >
                  Choose Images
                </Button>
                <Flex wrap="wrap">
                  {formData.images.map((image) => (
                    <Box key={image.id} position="relative" m="2">
                      <Image
                        src={`${process.env.REACT_APP_IMG_API}/${image.path}`}
                        boxSize={"200px"}
                        overflow="hidden"
                        position="relative"
                        borderRadius="xl"
                        bg="gray.100"
                        role="group"
                        objectFit={"contain"}
                        _hover={{ bg: "gray.200" }}
                      />
                      <IconButton
                        icon={<DeleteIcon />}
                        position="absolute"
                        top="0"
                        right="0"
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleImageDelete(image.id)}
                      />
                    </Box>
                  ))}
                  {newImages.map((image, index) => (
                    <Box key={index} position="relative" m="2">
                      <Image
                        src={URL.createObjectURL(image)}
                        alt="New ad image"
                        boxSize={"200px"}
                        overflow="hidden"
                        position="relative"
                        borderRadius="xl"
                        bg="gray.100"
                        role="group"
                        objectFit={"contain"}
                        _hover={{ bg: "gray.200" }}
                      />
                      <IconButton
                        icon={<CloseIcon />}
                        position="absolute"
                        top="0"
                        right="0"
                        size="sm"
                        colorScheme="red"
                        onClick={() => handleNewImageDelete(index)}
                      />
                    </Box>
                  ))}
                </Flex>
                {errors.images && (
                  <FormErrorMessage>{errors.images}</FormErrorMessage>
                )}
              </FormControl>

              <Input
                id="imageInput"
                type="file"
                multiple
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />

              <FormControl mt="4" isInvalid={errors.video}>
                <FormLabel>Video</FormLabel>
                {!formData.video && !newVideo && (
                  <Button
                    onClick={() => document.getElementById("videoInput").click()}
                    mb="4"
                    w={"200px"}
                  >
                    Upload Video
                  </Button>
                )}
                {formData.video && (
                  <Box
                    width="200px"
                    height="200px"
                    m="2"
                    position="relative"
                    // overflow="hidden"
                  >
                    <video width="100%" height="100%" controls>
                      <source
                        src={`${process.env.REACT_APP_IMG_API}/${formData.video.path}`}
                        type="video/mp4"
                      />
                    </video>
                    <IconButton
                      icon={<DeleteIcon />}
                      position="absolute"
                      top="0"
                      right="0"
                      size="sm"
                      colorScheme="red"
                      onClick={handleVideoDelete}
                    />
                  </Box>
                )}
                {newVideo && (
                  <Box
                    width="200px"
                    height="200px"
                    m="2"
                    position="relative"
                    // overflow="hidden"
                  >
                    <video width="100%" height="100%" controls>
                      <source
                        src={URL.createObjectURL(newVideo)}
                        type="video/mp4"
                      />
                    </video>
                    <IconButton
                      icon={<CloseIcon />}
                      position="absolute"
                      top="0"
                      right="0"
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleNewVideoDelete()}
                    />
                  </Box>
                )}
                {errors.video && (
                  <FormErrorMessage>{errors.video}</FormErrorMessage>
                )}
              </FormControl>

              <Input
                id="videoInput"
                type="file"
                hidden
                accept="video/*"
                onChange={handleVideoChange}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleSubmit}>
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default EditAdModal;
