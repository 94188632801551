import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { ChakraProvider, HStack, Stack } from "@chakra-ui/react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Text,
  Flex,
} from "@chakra-ui/react";
import { SearchIcon, DeleteIcon } from "@chakra-ui/icons";
import AddUserModal from "./AddUserModal";
import UpdateUserModal from "./UpdateUserModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { DeleteUser, SearchAgentsService } from "../../service/service";

function UserTable({
  userData,
  setIsLoading,
  setUserData,
  totalPages,
  currentPage,
  handlePageChange,
  noData,
  itemsPerPage,
  setTotalPages,
}) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData(currentPage, searchText);
  }, [currentPage, searchText]);

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure you want to delete this agent?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const formData = { id };
        DeleteUser(formData)
          .then((result) => {
            setIsLoading(false);
            if (result.status) {
              const updatedItems = userData?.filter((item) => item.id !== id);
              setUserData(updatedItems);
              Swal.fire({
                icon: "success",
                title: "User Deleted Successfully!",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                title: "Error!",
                text: result.message,
                icon: "error",
                timer: 3000,
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error.message);
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          });
      }
    });
  };

  const fetchData = (page, search) => {
    setIsLoading(true);

    SearchAgentsService({ searchText: search, per_page: itemsPerPage, page: page + 1 })
      .then((result) => {
        setIsLoading(false);
        if (result.status) {
          setUserData(result.data.data);
          setTotalPages(result.data.last_page);
        } else {
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  return (
    <div className="bg-[#F7F7F7] px-10 pb-8">
      <div className="relative overflow-x-auto shadow-md sm:rounded-2xl bg-white">
        <ChakraProvider>
          <Box width="100%" p="5">
            <Flex justifyContent="end" mb="4">
              {(adminData?.type === 0 || userPermission?.users_accounts?.create) && (
                <AddUserModal
                  setUserData={setUserData}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  searchText={searchText}
                />
              )}
            </Flex>
            <Stack alignItems={"end"}>
              <InputGroup mb="4" w="30%">
                <InputLeftElement pointerEvents="none">
                  <Icon as={SearchIcon} color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearchChange}
                  autoComplete="new-password"
                />
              </InputGroup>
            </Stack>
            {(adminData?.type === 0 || userPermission?.users_accounts?.read) && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Sr#</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Active Status</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userData?.map((user, index) => (
                    <Tr key={user.id}>
                      <Td>{index + 1 + currentPage * itemsPerPage}</Td>
                      <Td>{user.name}</Td>
                      <Td>{user.email}</Td>
                      <Td>{user.status === 1 ? "Active" : "Inactive"}</Td>
                      <Td>
                        <HStack spacing={2}>
                          {(adminData?.type === 0 || userPermission?.users_accounts?.update) && (
                            <UpdateUserModal
                              adminData={user}
                              setUserData={setUserData}
                              currentPage={currentPage}
                              itemsPerPage={itemsPerPage}
                              searchText={searchText}
                            />
                          )}
                          {(adminData?.type === 0 || userPermission?.users_accounts?.update) && (
                            <UpdatePasswordModal userId={user.id} />
                          )}
                          {(adminData?.type === 0 || userPermission?.users_accounts?.delete) && (
                            <DeleteIcon
                              cursor={"pointer"}
                              onClick={() => deleteUser(user?.id)}
                              color={"red"}
                            />
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            <Text mt="4">
              Showing {userData?.length} of {itemsPerPage} rows
            </Text>
          </Box>
        </ChakraProvider>
        <div className="pagination w-full bg-whiteGrey px-6">
          <ReactPaginate
            className="flex justify-end items-center gap-x-3 h-[80px]"
            pageCount={totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            initialPage={currentPage}
            previousLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.052 19.9968C17.337 19.6829 17.3113 19.1993 16.9947 18.9168L10.9244 13.5L16.9947 8.08324C17.3113 7.80069 17.337 7.31712 17.052 7.00317C16.767 6.68923 16.2793 6.66378 15.9627 6.94633L9.25534 12.9315C9.09281 13.0766 9 13.2832 9 13.5C9 13.7168 9.09281 13.9234 9.25534 14.0685L15.9627 20.0537C16.2793 20.3362 16.767 20.3108 17.052 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            nextLabel={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.94801 19.9968C9.66304 19.6829 9.6887 19.1993 10.0053 18.9168L16.0756 13.5L10.0053 8.08324C9.6887 7.80069 9.66303 7.31712 9.94801 7.00317C10.233 6.68923 10.7207 6.66378 11.0373 6.94633L17.7447 12.9315C17.9072 13.0766 18 13.2832 18 13.5C18 13.7168 17.9072 13.9234 17.7447 14.0685L11.0373 20.0537C10.7207 20.3362 10.233 20.3108 9.94801 19.9968Z"
                  fill="#161E2E"
                />
              </svg>
            }
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default UserTable;
