import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import orderPic from "../../assets/order_grey.svg";
import orderWhitePic from "../../assets/order_white.svg";
import adsPic from "../../assets/ads_grey.svg";
import adsWhitePic from "../../assets/ads_white.svg";
import bannerPic from "../../assets/banner_grey.svg";
import bannerWhitePic from "../../assets/banner_white.svg";
import { FiUsers } from "react-icons/fi";
import { GrUserSettings } from "react-icons/gr";
import { TbCategory } from "react-icons/tb";
import { IoBagAddSharp } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";
import { BiSolidDashboard } from "react-icons/bi";

import { Link } from "react-router-dom";
import { FetchPermissionsService } from "../../service/service";
import Swal from "sweetalert2";

function DashboardSideNav() {
  const currentPath = window.location.pathname;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});
  const adminData = JSON.parse(localStorage.getItem("adminData"));

  useEffect(() => {
    const userPermission = JSON.parse(localStorage.getItem("userPermissions"));
    setUserPermissions(userPermission);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const isAllPermissionsFalse = (permissions) => {
    return Object.values(permissions).every((perm) => perm === false);
  };

  return (
    <>
      <button
        onClick={toggleSidebar}
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-[#D2D6DC33] focus:outline-none focus:ring-2 focus:ring-gray-20"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={`${
          sidebarOpen ? "" : "-translate-x-full"
        } fixed top-0 left-0 z-40 w-48 lg:w-80 h-screen transition-transform sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div
          className="h-full bg-white py-4 pt-0 overflow-y-auto text-whiteGrey"
          id="style-3"
        >
          <div className="flex justify-center pb-5 px-[50px] lg:px-16 pt-6 border-b-[1px] border-whiteGrey">
            <img src={logo} alt="logo" className="w-[100px]" />
          </div>
          <div className="space-y-6 mt-8">
            {(adminData?.type === 0 ||
              (userPermissions?.dashboard &&
                !isAllPermissionsFalse(userPermissions?.dashboard))) && (
              <Link
                to="/dashboard"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/dashboard"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <BiSolidDashboard fontSize={24} />
                </span>
                <span className="ml-5 whitespace-nowrap">Dashboard</span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions?.users_accounts &&
                !isAllPermissionsFalse(userPermissions?.users_accounts))) && (
              <Link
                to="/user-management"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/user-management"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <FiUsers fontSize={24} />
                </span>
                <span className="ml-5 whitespace-nowrap">User Management</span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions?.categories &&
                !isAllPermissionsFalse(userPermissions?.categories))) && (
              <Link
                to="/categories"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/categories"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <TbCategory fontSize={24} />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Categories
                </span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions?.categories &&
                !isAllPermissionsFalse(userPermissions?.categories))) && (
              <Link
                to="/sub-categories"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/sub-categories"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <TbCategory fontSize={24} />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  SubCategories
                </span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions?.ads &&
                !isAllPermissionsFalse(userPermissions?.ads))) && (
              <Link
                to="/ads-management"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/ads-management"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <img
                    src={
                      currentPath === "/ads-management" ? adsWhitePic : adsPic
                    }
                    className="w-[24px]"
                  />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Ads Management
                </span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions?.banner &&
                !isAllPermissionsFalse(userPermissions?.banner))) && (
              <Link
                to="/banner-management"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/banner-management"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <img
                    src={
                      currentPath === "/banner-management"
                        ? bannerWhitePic
                        : bannerPic
                    }
                    className="w-[24px]"
                  />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Banner Management
                </span>
              </Link>
            )}
            {(adminData?.type === 0 ||
              (userPermissions.customer &&
                !isAllPermissionsFalse(userPermissions.customer))) && (
              <Link
                to="/customers-management"
                className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                  currentPath === "/customers-management"
                    ? "bg-[#087C7C] text-white"
                    : "text-[#4D5454]"
                }`}
              >
                <span>
                  <FaRegUser fontSize={24} />
                </span>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Customers Management
                </span>
              </Link>
            )}
            <Link
              to="/orders-management"
              className={`flex items-center py-3 px-10 mx-5 rounded-lg ${
                currentPath === "/orders-management"
                  ? "bg-[#087C7C] text-white"
                  : "text-[#4D5454]"
              }`}
            >
              <span>
                {/* <FaRegUser fontSize={24} /> */}
                <img
                  src={
                    currentPath === "/orders-management"
                      ? orderWhitePic
                      : orderPic
                  }
                  className="w-[24px]"
                />
              </span>
              <span className="flex-1 ml-3 whitespace-nowrap">
                Orders Management
              </span>
            </Link>
          </div>
        </div>
      </aside>
      {sidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="bg-primaryDark bg-opacity-50 fixed inset-0 z-30"
        ></div>
      )}
    </>
  );
}

export default DashboardSideNav;
