import React, { useEffect, useState } from "react";
import DashboardSideNav from "../../adminComponents/DashboardComponents/DashboardSideNav";
import DashboardHeader from "../../adminComponents/DashboardComponents/DashboardHeader";
import { ThreeCircles } from "react-loader-spinner";
import AddDetails from "../../adminComponents/addmanagement/AddDetails";

function AddDetailPage() {

  const [isLoading, setIsLoading] = useState(false);
  const buttonElement =  (
    <div className="flex gap-x-4">
    </div>
  );

  return (
    <>
      {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}

      <div className="bg-[#F7F7F7]">
        <DashboardSideNav />
        <main className="bg-[#F7F7F7] min-h-screen h-full sm:ml-[246px] lg:ml-[310px]">
          <DashboardHeader
            title={"Ad Details"}
            headerButton={buttonElement}
            subtitle={"Role Management"}
            showLowerHeader={false}
          />
          <AddDetails/>
        </main>
      </div>
    </>
  );
}

export default AddDetailPage;
