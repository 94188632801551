// src/PrivacyPolicy.js

import React from "react";
import { Box, Heading, Text, VStack, ChakraProvider } from "@chakra-ui/react";

const PrivacyPolicy = () => {
  return (
    <ChakraProvider>
      <Box fontFamily={"sans-serif"} py={8} px={10}>
        <VStack spacing={6} align="start">
          <Heading as="h1" size="xl" alignSelf={"center"}>
            Privacy Policy for Mandi
          </Heading>

          <Box>
            <Heading as="h2" size="md" mt={6} mb={2}>
              Introduction
            </Heading>
            <Text>
              Welcome to Mandi. We are committed to protecting your privacy and
              ensuring that your personal information is handled in a safe and
              responsible manner. This Privacy Policy explains how we collect,
              use, and safeguard your personal information when you use our
              e-commerce application, Mandi, which serves both sellers and
              buyers.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={6}>
              Information We Collect
            </Heading>
            <Text mb={4}>
              <strong>Seller Information:</strong> When a seller creates an ad
              post to sell a product, we collect necessary information such as
              name, contact details, product information, pricing, and any other
              details required to complete the ad post.
            </Text>
            <Text mb={4}>
              <strong>Buyer Information:</strong> When a buyer makes a purchase,
              we collect necessary information such as name, contact details and
              shipping address.
            </Text>
            <Text>
              <strong>Usage Data:</strong> We collect information on how you
              interact with the Mandi app, such as the features you use, the
              items you view, and the frequency of your usage.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={6}>
              How We Use Your Information
            </Heading>
            <Text mb={4}>
              <strong>Seller Services:</strong> We use the information provided
              by sellers to create and display ad posts, manage listings, and
              facilitate communication between sellers and buyers.
            </Text>
            <Text mb={4}>
              <strong>Buyer Services:</strong> We use the information provided
              by buyers to process purchases and arrange
              deliveries.
            </Text>
            <Text>
              <strong>To Improve the App:</strong> We analyze usage data to
              improve our features, enhance user experience, and ensure the
              Mandi app runs smoothly.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={2}>
              Data Sharing and Disclosure
            </Heading>
            <Text>
              We do not share your personal information with third parties
              except to provide the services described above, comply with legal
              obligations, or with your consent.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={2}>
              Data Security
            </Heading>
            <Text>
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access, use,
              or disclosure.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={6}>
              Your Rights
            </Heading>
            <Text mb={4}>
              <strong>Access and Control:</strong> You have the right to access,
              correct, or delete your personal information. Sellers can update
              their ad posts and contact details, and buyers can update their
              shipping and payment details through the app settings.
            </Text>
            <Text>
              <strong>Data Removal Requests:</strong> You can request the
              removal of your data by contacting our support team. We will
              facilitate these requests but cannot guarantee that all third
              parties will comply.
            </Text>
          </Box>

          <Box>
            <Heading as="h2" size="md" mt={4} mb={2}>
              Changes to This Privacy Policy
            </Heading>
            <Text>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on our
              website and within the app.
            </Text>
          </Box>

          <Box w='100%'>
            <Heading as="h2" size="md" mt={4} mb={2}>
              Contact Us
            </Heading>
            <Text mb={8}>
              If you have any questions about this Privacy Policy or our data
              practices, please contact us at support@mandi.com.
            </Text>
            <Text textAlign={'center'}>
              By using Mandi, you agree to the terms of this Privacy Policy.
            </Text>
          </Box>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default PrivacyPolicy;
