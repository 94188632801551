import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import LoginHeader from "../LoginHeader/LoginHeader";
import { RemoveCustomerService } from "../../service/service";

function RemoveCustomer() {
  const [phone, setPhone] = useState("");
  // console.log("🚀 ~ RemoveCustomer ~ phone:", phone)
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const FormData = {
      phone_number: phone,
    };
    setLoading(true);
    RemoveCustomerService(FormData)
      .then((result) => {
        if (result.status) {
          setErrorMsg("");
          setLoading(false);
          Swal.fire("Success!", result.message, "success");
        } else {
          setErrorMsg(result.message);
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.message);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <div className="w-full pb-3">
      <LoginHeader />
      <div className="max-w-[611px] mx-auto px-2 xs:px-4 sm:px-14 space-y-4">
        <div className="text-2xl font-bold leading-[28px] text-center">
          Enter Phone Number
        </div>
        <div id="recaptcha-container"></div>
        <div className="space-y-[14px]">
          <div className="h-[70px] rounded-lg bg-[#D2D6DC] border-[2px] border-[#CED4DA] bg-opacity-[0.4] pt-2 px-4">
            <label
              htmlFor="phone"
              className="block text-sm font-normal leading-5 text-[grey]"
            >
              Phone Number
            </label>
            <PhoneInput
              country={"pk"}
              value={phone}
              onChange={handlePhoneChange}
              inputProps={{
                required: true,
                autoFocus: true,
              }}
              onlyCountries={["pk"]}
            />
          </div>
          {/* {errorMsg !== "" && <p className="text-red-500 px-3">{errorMsg}</p>} */}
          <div className="pt-[10px]">
            <button
              onClick={handleSubmit}
              disabled={loading || !phone}
              className="flex w-full h-14 items-center justify-center rounded-md bg-primaryDark hover:bg-opacity-[0.9] focus:bg-primtext-primaryDark px-3 py-1.5 text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
            >
              Delete Customer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RemoveCustomer;
