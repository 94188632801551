import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  Text,
} from "@chakra-ui/react";
import {
  AddAdminUser,
  AdminListUser,
  SearchAgentsService,
} from "../../service/service";
import Swal from "sweetalert2";

function AddUserModal({ setUserData, currentPage, itemsPerPage, searchText }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const initialPermissions = {
    ads: { read: false, update: false },
    banner: { create: false, read: false, update: false, delete: false },
    categories: { create: false, read: false, update: false, delete: false },
    customer: { create: false, read: false, update: false, delete: false },
    dashboard: { read: false },
    order: { read: false, update: false },
    slider: { create: false, read: false, update: false, delete: false },
    users_accounts: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  };

  const [permissions, setPermissions] = useState(initialPermissions);
  const [loading, setLoading] = useState(false);

  const allPermissionTypes = ["create", "read", "update", "delete"];

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name) {
      isValid = false;
      errors.name = "Name is required.";
    }

    if (!formData.email) {
      isValid = false;
      errors.email = "Email is required.";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      isValid = false;
      errors.email = "Email is invalid.";
    }

    if (!formData.password) {
      isValid = false;
      errors.password = "Password is required.";
    } else if (formData.password.length < 8) {
      isValid = false;
      errors.password = "Password must be at least 8 characters long.";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePermissionChange = (module, perm) => {
    setPermissions((prev) => ({
      ...prev,
      [module]: {
        ...prev[module],
        [perm]: !prev[module][perm],
      },
    }));
  };

  const toggleColumn = (perm) => {
    const allChecked = Object.keys(permissions).every(
      (module) =>
        !permissions[module].hasOwnProperty(perm) || permissions[module][perm]
    );
    setPermissions((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((module) => {
        if (prev[module].hasOwnProperty(perm)) {
          updated[module][perm] = !allChecked;
        }
      });
      return updated;
    });
  };

  const allChecked = (perm) => {
    return Object.keys(permissions).every(
      (module) =>
        !permissions[module].hasOwnProperty(perm) || permissions[module][perm]
    );
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        setLoading(true);
        const data = {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          permissions: permissions,
        };
        const result = await AddAdminUser(data);
        if (result.status) {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            password: "",
          });
          onClose();
          const userList = await SearchAgentsService({
            searchText,
            per_page: itemsPerPage,
            page: currentPage + 1,
          });
          if (userList.status) {
            setUserData(userList.data.data);
          } else {
            Swal.fire({
              title: "Error!",
              text: userList.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
          Swal.fire({
            icon: "success",
            title: result.message,
            showConfirmButton: false,
            timer: 1500,
          });
          setPermissions(initialPermissions);
        } else {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: result.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.log(error.message);
        setLoading(false);
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          timer: 3000,
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <ChakraProvider>
      <Button onClick={onOpen} colorScheme="teal">
        Add User
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setPermissions(initialPermissions);
          setFormData({
            name: "",
            email: "",
            password: "",
          });
          onClose();
        }}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Add User</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex" py={6}>
            <Box flex="1" pr="5">
              <FormControl isRequired isInvalid={formErrors.name}>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {formErrors.name && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.name}
                  </Text>
                )}
              </FormControl>

              <FormControl mt="4" isRequired isInvalid={formErrors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.email}
                  </Text>
                )}
              </FormControl>

              <FormControl mt="4" isRequired isInvalid={formErrors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {formErrors.password && (
                  <Text color="red.500" fontSize="sm">
                    {formErrors.password}
                  </Text>
                )}
              </FormControl>
            </Box>
            <Box flex="2">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th fontFamily={"Nunito Sans"} fontSize="lg">
                      Module/Permissions
                    </Th>
                    {allPermissionTypes.map((type) => (
                      <Th
                        fontFamily={"Nunito Sans"}
                        textTransform={"capitalize"}
                        key={type}
                      >
                        <Checkbox
                          isChecked={allChecked(type)}
                          onChange={() => toggleColumn(type)}
                        >
                          {type}
                        </Checkbox>
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(permissions).map((module, index) => (
                    <Tr key={index}>
                      <Td>{module}</Td>
                      {allPermissionTypes.map((perm) => (
                        <Td key={perm}>
                          {permissions[module].hasOwnProperty(perm) && (
                            <Checkbox
                              isChecked={permissions[module][perm]}
                              onChange={() =>
                                handlePermissionChange(module, perm)
                              }
                            />
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setPermissions(initialPermissions);
                setFormData({
                  name: "",
                  email: "",
                  password: "",
                });
                onClose();
              }}
            >
              Close
            </Button>
            <Button isLoading={loading} onClick={handleSave} colorScheme="teal">
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AddUserModal;
