import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {


  const user = localStorage.getItem("adminToken");

  return user ? children : <Navigate to="/unauthorized" />;
};

export default PrivateRoute;
