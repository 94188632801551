import React, { useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  useDisclosure,
  Box,
  Divider,
  ChakraProvider,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import {
  AddBannerService,
  AddSubCategoryService,
  GetBannerService,
  GetSubCategoriesList,
} from "../../service/service";
import Swal from "sweetalert2";
import { CloseIcon } from "@chakra-ui/icons";
import { ThreeCircles } from "react-loader-spinner";

function AddBannerImagesModal({ setResponseData, setTotalPages }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    title: "",
    images: [],
  });
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prev) => ({ ...prev, images: [...prev.images, ...files] }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index),
    }));
  };

  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Banner text is required.";
    }
    if (!formData.title) {
      errors.bannerType = "Title is required.";
    }
    if (formData.images.length === 0) {
      errors.images = "At least one image is required.";
    }
    return errors;
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      const payload = new FormData();
      payload.append("banner_name", formData.title);
      payload.append("banner_description", formData.name);
      formData.images.forEach((image, index) => {
        payload.append(`banner_images[${index}]`, image);
      });

      // Log the FormData to the console
      // console.log("FormData contents:");
      // for (let pair of payload.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      setIsLoading(true);
      AddBannerService(payload)
        .then((result) => {
          setIsLoading(false);
          if (result.status) {
              GetBannerService()
                .then((result) => {
                  if (result.status) {
                    setResponseData(result.data);
                    const totalPages = Math.ceil(result.data.total / 10);
                    setTotalPages(totalPages);
                  } else {
                    Swal.fire({
                      title: "Error!",
                      text: result.message,
                      icon: "error",
                      timer: 3000,
                      confirmButtonText: "OK",
                    });
                  }
                })
                .catch((error) => {
                  setIsLoading(false);
                  console.log(error.message);
                  Swal.fire({
                    title: "Error!",
                    text: error.message,
                    icon: "error",
                    timer: 3000,
                    confirmButtonText: "OK",
                  });
                });
            Swal.fire({
              icon: "success",
              title: "Banner Added Successfully!",
              showConfirmButton: false,
              timer: 1500,
            });
            onClose();
          } else {
            Swal.fire({
              title: "Error!",
              text: result.message,
              icon: "error",
              timer: 3000,
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            timer: 3000,
            confirmButtonText: "OK",
          });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <ChakraProvider>
         {isLoading && (
        <div className="overlay">
          <div className="loaderImage-container">
            <ThreeCircles
              height="100"
              width="100"
              color="#F9F9F9"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      )}
      <Button onClick={onOpen} colorScheme="teal">
        Add Banner
      </Button>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          setErrors({});
          onClose();
        }}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#4D5454">Add Banner</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody display="flex">
            <Box flex="1" pr="5">
              <FormControl mt={3} isRequired isInvalid={errors.name}>
                <FormLabel>Banner Title</FormLabel>
                <Input
                  placeholder="Enter banner title here"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={3} isRequired isInvalid={errors.name}>
                <FormLabel>Banner Text</FormLabel>
                <Input
                  placeholder="Enter banner text in urdu here"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={3} isInvalid={errors.images}>
                <FormLabel>Banner Images</FormLabel>
                <Box border="1px dashed gray" p={4} borderRadius="md">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50px"
                    cursor="pointer"
                    onClick={() => fileInputRef.current.click()}
                  >
                    <Text>Choose Images</Text>
                  </Box>
                  <Input
                    type="file"
                    multiple
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <Box display="flex" flexWrap="wrap" mt={4}>
                    {formData?.images?.map((image, index) => (
                      <Box
                        key={index}
                        position="relative"
                        m={2}
                        borderRadius="md"
                      >
                        <Image
                          src={URL.createObjectURL(image)}
                          alt={`banner-image-${index}`}
                          boxSize="100px"
                          objectFit="cover"
                          borderRadius="md"
                        />
                        <IconButton
                          size="sm"
                          colorScheme="red"
                          aria-label="Remove image"
                          icon={<CloseIcon />}
                          position="absolute"
                          top="0"
                          right="0"
                          onClick={() => handleRemoveImage(index)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
                {errors.images && (
                  <FormErrorMessage>{errors.images}</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr="3"
              onClick={() => {
                setErrors({});
                onClose();
              }}
            >
              Close
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="teal"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default AddBannerImagesModal;
